import React from 'react'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import { ListItem, ListSubheader, makeStyles } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    fontSize: theme.typography.pxToRem(14),
  },
  spacing: {
    marginTop: 16,
  },
  card: {
    borderRadius: 6,
    border: '1px solid #EBEBEB',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
  },
  header: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    paddingLeft: 14,
    color: theme.palette.common.white,
    backgroundColor: 'black',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(45),
  },
  discountHeader: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& span:first-child': {
      marginRight: 8,
    },
  },
  expend: {
    padding: '10px 8px',
    color: '#FAAB00',
  },
  entries: {
    margin: '0 16px',
    '& > *': {
      padding: '16px',
    },
    '& > *:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  discountList: {
    borderRadius: 6,
    border: '1px solid #EBEBEB',
    width: '100%',
    height: '100%',
    paddingBottom: 0,
  },
  listSubheader: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    paddingLeft: 14,
    color: theme.palette.common.white,
    backgroundColor: 'black',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(45),
  },
  discountEven: {
    paddingTop: 14,
    paddingBottom: 14,
    backgroundColor: '#FFFFFF',
    border: '#EBEBEB',
  },
  discountOdd: {
    paddingTop: 14,
    paddingBottom: 14,
    backgroundColor: '#F7F8FA',
    border: '#EBEBEB',
  },
  discountDetails: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 14,
    paddingBottom: 14,
    marginTop: 14,
  },
  discountAmount: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    paddingTop: 14,
    marginTop: 14,
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  discountHeaderOpened: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 600,
  },
  discountHeaderClosed: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    paddingBottom: 3,
  },
  discountSubheader: {
    fontSize: theme.typography.pxToRem(13),
    color: '#7E8186',
  },
  discountHeaderPercentValue: {
    color: '#7E8186',
  },
  discountDescription: {
    paddingTop: 14,
  },
}))

function DiscountMerchantDetails({ title, value }) {
  return value ? (
    <Grid container>
      <Grid item xs={6} md>
        <b>{title}</b>
      </Grid>
      <Grid item xs={6} md>
        <b>{value}</b>
      </Grid>
    </Grid>
  ) : null
}

export function DiscountList({ user, list }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(-1)

  if (!user || !list || list.length === 0) {
    return <h3>{t('discounts.not-found')}</h3>
  }

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          className={classes.listSubheader}
          component="div"
          id="nested-list-subheader"
        >
          {user.firstName} {user.lastName}
        </ListSubheader>
      }
      className={classes.discountList}
    >
      {list.map((discount, i) => {
        const isDiscountOpen = open === i
        const merchant = discount.merchant
        return (
          <ListItem
            key={`discount-${i}`}
            className={i % 2 === 0 ? classes.discountEven : classes.discountOdd}
            button
            onClick={() => setOpen(isDiscountOpen ? -1 : i)}
          >
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column" width="100%">
                  <span
                    className={
                      isDiscountOpen
                        ? classes.discountHeaderOpened
                        : classes.discountHeaderClosed
                    }
                  >
                    {merchant.name}
                    {!isDiscountOpen && discount.amountPercent && (
                      <span className={classes.discountHeaderPercentValue}>
                        {' '}
                        ({discount.amountPercent}%)
                      </span>
                    )}
                  </span>
                  {!isDiscountOpen && (
                    <span className={classes.discountSubheader}>
                      {discount.name}
                    </span>
                  )}
                </Box>
                {isDiscountOpen ? <ExpandLess /> : <ExpandMore />}
              </Box>
              <Collapse in={isDiscountOpen} timeout="auto" unmountOnExit>
                <div className={classes.discountDetails}>
                  <DiscountMerchantDetails
                    title={t('discounts.address')}
                    value={merchant.address}
                  />
                  <DiscountMerchantDetails
                    title={t('discounts.phone')}
                    value={merchant.phone}
                  />
                  <DiscountMerchantDetails
                    title={t('discounts.email')}
                    value={merchant.email}
                  />
                  <DiscountMerchantDetails
                    title={t('discounts.webpage')}
                    value={merchant.webpage}
                  />
                  <div className={classes.discountDescription}>
                    <b>{t('discounts.description')}:</b>
                    <br />
                    {discount.description}
                  </div>
                </div>
                {discount.amountPercent && (
                  <div className={classes.discountAmount}>
                    <div>{t('discounts.amount')}</div>
                    <div>{discount.amountPercent}%</div>
                  </div>
                )}
              </Collapse>
            </Box>
          </ListItem>
        )
      })}
    </List>
  )
}
