import React, { useEffect, useState } from 'react'
import {useMutation, useLazyQuery} from '@apollo/client'
import Error from '../elements/Error'
import { GET_AUTHENTICATION_CONTEXT } from '../../graphql/query'
import {
  PHONE_AUTH_NEW_CODE,
  PHONE_AUTH_NEW_TOKEN,
  SAVE_OWN_PARAMETERS,
  ASSOCIATE_ACCOUNTS,
  PHONE_AUTH_CHANGE_PHONE,
  REMOVE_ACCOUNT_ASSOCIATION
} from '../../graphql/mutation'
import { useTranslation } from 'react-i18next'

export const SIAULIAI_AUTH_TOKEN = 'siauliai-token'
export const AUTHENTICATOR_MOBILEAUTH = "MOBILEAUTH"
export const AUTHENTICATOR_VIISP = "Viisp"

export const PARAM_FIRST_NAME = 'firstName'
export const PARAM_LAST_NAME = 'lastName'
export const PARAM_EMAIL = 'email'
export const PARAM_LANGUAGE = 'language'
export const PARAM_ADDRESS = 'address'
export const PARAM_PHONE = 'phone'
export const PARAM_AGREE_PORTAL_TERMS = 'agreePortalTerms'

export const PARAM_AUTH_METHODS = 'authenticators'
export const PARAM_IS_MERGED = 'isAssociated'

// This one is special and is not stored as param, but as uniqueId
export const PARAM_PERSONAL_CODE = 'personalCode'

const PROFILE_PARAMS = [
  PARAM_FIRST_NAME,
  PARAM_LAST_NAME,
  PARAM_EMAIL,
  PARAM_PHONE,
  PARAM_ADDRESS,
  PARAM_LANGUAGE,
  PARAM_AGREE_PORTAL_TERMS
]

const Context = React.createContext({})

export function useAuthentication() {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error(
        'useAuthentication must be used within a SiauliaiAuthenticationProvider',
    )
  }
  return context
}

export default function SiauliaiAuthenticationProvider({ value, children }) {
  const storageToken = localStorage.getItem(SIAULIAI_AUTH_TOKEN);
  const [authToken, setAuthToken] = useState(storageToken && storageToken !== "null" ? storageToken : null)
  const [profile, setProfile] = React.useState()
  const [isAuthContextAvailable, setIsAuthContextAvailable] = React.useState(false)
  const [mobileAccountId, setMobileAccountId] = React.useState()

  const { t } = useTranslation()

  const [generateCode] = useMutation(PHONE_AUTH_NEW_CODE, {
    fetchPolicy: 'no-cache',
  })

  const [generateToken] = useMutation(PHONE_AUTH_NEW_TOKEN, {
    fetchPolicy: 'no-cache',
  })

  const [changePhoneMutation] = useMutation(PHONE_AUTH_CHANGE_PHONE, {
    fetchPolicy: 'no-cache',
  })

  const [saveOwnParameters] = useMutation(SAVE_OWN_PARAMETERS, {
    fetchPolicy: 'no-cache',
  })

  const [associateAccounts] = useMutation(ASSOCIATE_ACCOUNTS, {
    fetchPolicy: 'no-cache',
  })

  const [removeAccountAssociation] = useMutation(REMOVE_ACCOUNT_ASSOCIATION, {
    fetchPolicy: 'no-cache',
  })

  const [getAuthenticationContext] = useLazyQuery(GET_AUTHENTICATION_CONTEXT, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const params = data?.authorizationProvider?.ownAuthenticationContext?.params
      const authenticator = data?.authorizationProvider?.ownAuthenticationContext?.authenticator
      const associatedAccounts = data?.authorizationProvider?.ownAuthenticationContext?.associatedAccounts

      setMobileAccountId(associatedAccounts?.find(acc => acc.authenticator == AUTHENTICATOR_MOBILEAUTH)?.id)

      if (params) {
        const getParam = key => {
          const param = params.find(p => p.key === key)
          return {
            value: param?.value || "",
            canEdit: param?.isEditable === undefined ? true : param.isEditable
          }
        }
        const userProfile = {}
        PROFILE_PARAMS.forEach(param => {
          userProfile[param] = getParam(param)
        })
        userProfile[PARAM_PERSONAL_CODE] = getParam(PARAM_PERSONAL_CODE)

        userProfile[PARAM_IS_MERGED] = associatedAccounts && associatedAccounts.length > 0
        userProfile[PARAM_AUTH_METHODS] = [authenticator].concat(associatedAccounts?.map(acc => acc.authenticator))
        setProfile(userProfile)
        setIsAuthContextAvailable(true)
      }
    },
  })

  useEffect(() => {
    localStorage.setItem(SIAULIAI_AUTH_TOKEN, authToken)
    getAuthenticationContext();
  }, [authToken])

  const updateToken = (token, skipMerge = false) => {
    return new Promise(async(resolve, reject)=> {
      if(authToken && authToken !== token) {
        try {
          if(!skipMerge){
            await mergeAccounts(token)
          }
          getAuthenticationContext()
        } catch (error) {
          reject('Failed to update token')
        }
      } else {
        setAuthToken(token)
      }
      resolve()
    });
  }

  const getPhoneCode = phone => {
    return generateCode({ variables: { phone } }).then(res => {
      if (res?.data?.phoneAuthentication?.newCode?.phone) {
        return Promise.resolve(res?.data?.phoneAuthentication?.newCode?.phone)
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
    })
  }

  const verifyPhoneCode = (phone, code) => {
    return new Promise(async(resolve, reject)=> {
        try {
          const data = await generateToken({
            variables: {
              input: {
                phone,
                code,
              },
            },
          })
          if (data?.data?.phoneAuthentication?.newToken) {
            const { token } = data.data.phoneAuthentication.newToken
            await updateToken(token)
          } else {
            reject('Token not set')
          }
          resolve(); 
        } catch (error) {
          reject(error)
        }
    });
  }

  const changePhone = (phone, code) => {
    return new Promise(async(resolve, reject)=> {
        try {
          const data = await changePhoneMutation({
            variables: {
              input: {
                phone,
                code
              },
            },
          })
          if (data?.data?.phoneAuthentication?.changePhone) {
            const { token } = data.data.phoneAuthentication.changePhone
            await updateToken(token, true)
          } else {
            reject('Token not set')
          }
          resolve(); 
        } catch (error) {
          reject(error)
        }
    });
  }

  const removeMobileAccount = () => {
    if(mobileAccountId > 0){
      return removeAccountAssociation({
        variables: {
          userId: mobileAccountId
        }
      }).then(res => {
        if (res?.data?.authorizationUsers?.removeAccountAssociation) {
          getAuthenticationContext()
          return Promise.resolve(true)
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject()
        }
      })
    }
  }

  /**
   * Saves authentication context profile parameters
   * @param params in the format of {key, value}
   * @return {Promise<boolean>}
   */
  const saveProfileParams = params => {
    return saveOwnParameters({ variables: { params }})
        .then(res => {
          if (res?.data?.authorizationUsers?.saveOwnParameters) {
            getAuthenticationContext();
            return Promise.resolve(true)
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject()
          }
        });
  }

  const logout = () => {
    localStorage.removeItem(SIAULIAI_AUTH_TOKEN)
    setAuthToken(null)
    setProfile(null)
    setIsAuthContextAvailable(false)
  }

  const mergeAccounts = (token) => {
    return associateAccounts({
      variables: {
        targetAccountToken: token,
      }
    }).then(res => {
      if (res?.data?.authorizationUsers?.associateAccounts) {
        return Promise.resolve(true)
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
    })
  }

  const hasVIISPAuth = () => {
    if(!profile?.[PARAM_AUTH_METHODS]) return false
    return profile[PARAM_AUTH_METHODS].indexOf(AUTHENTICATOR_VIISP) > -1;
  }

  const hasMobileAuth = () => {
    if(!profile?.[PARAM_AUTH_METHODS]) return false
    return profile[PARAM_AUTH_METHODS].indexOf(AUTHENTICATOR_MOBILEAUTH) > -1;
  }

  return (
      <Context.Provider
          value={{
            isAuthContextAvailable,
            hasVIISPAuth,
            hasMobileAuth,
            authToken,
            setAuthToken,
            profile,
            setProfile,
            updateToken,
            saveProfileParams,
            getPhoneCode,
            verifyPhoneCode,
            changePhone,
            removeMobileAccount,
            logout,
          }}
      >
        {children}
      </Context.Provider>
  )
}
