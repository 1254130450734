import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { GET_AVILYS_TEMPLATES } from '../../../graphql/query'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

export const TemplateAutocomplete = withStyles(theme => ({
  root: {
    width: '100%',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
  },
}))(Autocomplete)

export const TemplateTextField = withStyles(theme => ({
  root: {
    width: '100%',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
  },
}))(TextField)

export default function DocumentTemplateAutocomplete({
  selectedServiceId,
  setSelectedServiceId,
}) {
  const { t } = useTranslation()
  const { data, error, loading } = useQuery(GET_AVILYS_TEMPLATES)
  if (loading) {
    return <CircularProgress />
  }
  const value = data?.avilys?.templates.find(
    template => template.id === selectedServiceId,
  )
  return (
    <Box style={{ width: '100%' }}>
      <TemplateAutocomplete
        style={{ width: '100%' }}
        options={data?.avilys?.templates}
        loading={loading}
        value={value}
        onChange={(event, newValue) => {
          setSelectedServiceId(newValue.id)
        }}
        getOptionLabel={template => (template ? template.name : '')}
        disableClearable
        renderInput={params => (
          <TemplateTextField
            {...params}
            margin="dense"
            variant="outlined"
            fullWidth
            placeholder={t('select-service')}
          />
        )}
        error={error}
        noOptionsText={t('table.no-data')}
      />
    </Box>
  )
}
