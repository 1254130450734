import React from 'react'

export default function ArrowDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 377.20002 202.89999"
      {...props}
    >
      <path d="M14.4 0L0 14.3l174.3 174.3 14.3 14.3L377.2 14.3 362.9 0 188.6 174.3z" />
    </svg>
  )
}
