import gql from 'graphql-tag'

export const CREATE_VIISP_AUTH_TICKET = gql`
    mutation CreateVIISPAuthTicket($input: AuthTicketCustomData!) {
        viisp {
            createAuthTicket(input: $input) {
                ticket
                url
            }
        }
    }
`

export const ASSOCIATE_ACCOUNTS = gql`
    mutation associateAccounts($targetAccountToken: String!) {
        authorizationUsers {
            associateAccounts(targetAccountToken: $targetAccountToken)
        }
    }
`

export const SAVE_OWN_PARAMETERS = gql`
    mutation SaveOwnParameters($params: [AuthorizationUserOwnEditableParameter!]!) {
        authorizationUsers {
            saveOwnParameters(input: $params)
        }
    }
`

export const PHONE_AUTH_NEW_CODE = gql`
    mutation PhoneAuthNewCode($phone: String!) {
        phoneAuthentication {
            newCode(phone: $phone) {
                phone
                sendToPhone
            }
        }
    }
`;

export const PHONE_AUTH_NEW_TOKEN = gql`
    mutation PhoneAuthNewToken($input: PhoneAuthenticationNewTokenInput!) {
        phoneAuthentication {
            newToken(input: $input) {
                token
            }
        }
    }
`;

export const PHONE_AUTH_CHANGE_PHONE = gql`
    mutation PhoneAuthChangePhone($input: PhoneAuthenticationNewTokenInput!) {
        phoneAuthentication {
            changePhone(input: $input) {
                token
            }
        }
    }
`;

export const REMOVE_ACCOUNT_ASSOCIATION = gql`
    mutation removeAccountAssociation($userId: Long!) {
        authorizationUsers {
            removeAccountAssociation(userId: $userId)
        }
    }
`

export const LOG_USAGE = gql`
    mutation logUsage($organizationId: String!) {
        siauliaiDiscounts{
            logUsage(organizationId: $organizationId){
                success
            }
        }
    }
`