import React from 'react'

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function SiauliaiPreloader() {
    return (
        <Box width="100%" mt={2} display="flex" alignContent="center" alignItems="center" justifyContent="center">
            <CircularProgress color="secondary" />
        </Box>
    )
}