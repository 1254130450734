import React from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 32,
    height: 32,
    marginRight: 8,
    color: theme.palette.error.dark,
  },
  message: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
  },
}))

export default function Error({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ErrorIcon className={classes.icon} />
      <Typography className={classes.message}>{children}</Typography>
    </div>
  )
}
