import React from 'react'

export default function Menu3Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Capa_1"
      viewBox="0 0 17.4 13.8"
      {...props}
    >
      <path
        d="M3.3 12.5c-.2.3-.2.7.1.9.3.2.7.2.9-.1l9.9-11.9c.2-.3.2-.7-.1-.9-.3-.2-.7-.2-.9.1L3.3 12.5zM13.7 7.4c1.5 0 2.7 1.2 2.7 2.7 0 1.5-1.2 2.7-2.7 2.7-1.5 0-2.7-1.2-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7m0-1.1c-2.1 0-3.8 1.7-3.8 3.8 0 2.1 1.7 3.8 3.8 3.8s3.8-1.7 3.8-3.8c-.1-2.1-1.8-3.8-3.8-3.8zM3.8 1.1c1.5 0 2.7 1.2 2.7 2.7 0 1.5-1.2 2.7-2.7 2.7-1.5 0-2.7-1.2-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7m0-1.1C1.7 0 0 1.7 0 3.8s1.7 3.8 3.8 3.8c2.1 0 3.8-1.7 3.8-3.8S5.8 0 3.8 0z"
        className="st0"
      />
    </svg>
  )
}
