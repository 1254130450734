import React from 'react'

export default function CloseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 173.2 173.2"
      {...props}
    >
      <path
        fill="#cbd0d8"
        d="M155.2 173.2c-2.2 0-4.2-.8-5.7-2.4l-62.9-62.9-62.9 62.9c-1.5 1.5-3.6 2.4-5.7 2.4s-4.2-.8-5.7-2.4L2.4 161c-3.2-3.2-3.2-8.3 0-11.5l62.9-62.9L2.4 23.7c-3.2-3.2-3.2-8.3 0-11.5l9.9-9.9C13.8.8 15.8 0 18 0s4.2.8 5.7 2.4l62.9 62.9 62.9-62.9C151 .9 153.1 0 155.2 0s4.2.8 5.7 2.4l9.9 9.9c3.2 3.2 3.2 8.3 0 11.5l-62.9 62.9 62.9 62.9c3.2 3.2 3.2 8.3 0 11.5l-9.9 9.9c-1.4 1.4-3.5 2.2-5.7 2.2z"
      />
    </svg>
  )
}
