import React from 'react'

export default function BurgerIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Capa_1"
      viewBox="0 0 227 196.7"
      {...props}
    >
      <path
        d="M210.6 38.8H16.4C7.3 38.8 0 31.4 0 22.4v-6C0 7.3 7.3 0 16.4 0h194.3c9 0 16.4 7.3 16.4 16.4v6c-.1 9-7.4 16.4-16.5 16.4zM210.6 117.7H16.4c-9 0-16.4-7.3-16.4-16.4v-6C0 86.3 7.3 79 16.4 79h194.3c9 0 16.4 7.3 16.4 16.4v6c-.1 9-7.4 16.3-16.5 16.3zM210.6 196.7H16.4c-9 0-16.4-7.3-16.4-16.4v-6c0-9 7.3-16.4 16.4-16.4h194.3c9 0 16.4 7.3 16.4 16.4v6c-.1 9.1-7.4 16.4-16.5 16.4z"
        className="st0"
      />
    </svg>
  )
}
