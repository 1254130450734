import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import CloseIcon from '@material-ui/icons/Close'
import { useAppState } from '@mcity/siauliai-core/src/components/providers/AppStateProvider'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  container: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: '10001',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  wrap: {
    position: 'relative',
    top: 'env(safe-area-inset-top)',
    left: 'calc(50% - 16px)',
    margin: '16px',
    padding: '16px',
    borderRadius: '8px',
    maxWidth: '480px',
    transform: 'translateX(-50%)',
    display: 'flex',
    backgroundColor: 'rgb(255, 244, 229)',
    color: 'rgb(102, 60, 0)',
  },
  innerWrap: {
    margin: '0 16px',
    width: '100%',
  },
  title: {
    margin: '4px 0 0',
    fontSize: '0.9rem',
  },
  message: {
    margin: '8px 0 0',
  },
  closeLink: {
    height: '24px',
    color: 'inherit',
  },
}))

export const OfflineNotice = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isOnline } = useAppState()

  const [canShowOfflineNotice, setCanShowOfflineNotice] = useState(true)

  useEffect(() => {
    if (isOnline) {
      setCanShowOfflineNotice(true)
    }
  }, [isOnline])

  const onClickHandler = event => {
    event.preventDefault()
    setCanShowOfflineNotice(false)
  }

  return (
    <>
      {!isOnline && canShowOfflineNotice ? (
        <div className={classes.container}>
          <div className={classes.wrap}>
            <ReportProblemOutlinedIcon style={{ color: '#ff9800' }} />
            <div className={classes.innerWrap}>
              <h4 className={classes.title}>{t('no-connection-title')}</h4>
              <p className={classes.message}>{t('no-connection-message')}</p>
            </div>
            <a className={classes.closeLink} href="#" onClick={onClickHandler}>
              <CloseIcon />
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
