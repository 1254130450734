import React, { useState, useMemo, useEffect } from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import MuiButton from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Section,
  Error,
  GridValue,
  GridTitle,
  GridContainer,
  ProfileChild,
  InputLabel,
  InputBase,
} from '../elements'
import { useAuthentication } from '@mcity/siauliai-core/src/components/providers'
import {
  PARAM_ADDRESS,
  PARAM_EMAIL,
  PARAM_FIRST_NAME,
  PARAM_LAST_NAME,
  PARAM_PERSONAL_CODE,
  PARAM_PHONE,
  PARAM_AGREE_PORTAL_TERMS,
} from '@mcity/siauliai-core/src/components/providers/SiauliaiAuthenticationProvider'
import { useTranslation } from 'react-i18next'
import { LANGUAGES } from '@mcity/siauliai-core/src/lng/i18n'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

export const GET_CHILDREN = gql`
  query {
    children {
      listChildren {
        personCode
        name
        surname
        birthDate
        phone
      }
    }
  }
`

const PHONE_NUMBER_LENGTH = 12

export const useStyles = makeStyles(theme => ({
  root: {
    borderTop: 'none !important',
  },
  warning: {
    textAlign: 'center',
    width: '60vw',
    color: 'red',
  },
  cardIcon: {
    verticalAlign: 'middle',
    color: '#FAAB00',
  },
  childrenSection: {
    marginTop: '16px',
  },
  childSelect: {
    width: '100%',
    marginBottom: '16px',
  },
  childSelectIconOutlined: {
    right: '20px',
  },
}))

const LanguageButton = withStyles(theme => ({
  root: {
    padding: '4px 16px',
    minWidth: 'auto',
    '&$selected': {
      background: '#FFECA6',
    },
  },
  selected: {},
}))(
  React.forwardRef(({ classes, children, selected, ...rest }, ref) => (
    <MuiButton
      ref={ref}
      className={clsx(classes.root, { [classes.selected]: selected })}
      {...rest}
    >
      {children}
    </MuiButton>
  )),
)

function LanguageSwitch() {
  const { i18n } = useTranslation()
  const changeLanguage = l => {
    i18n.changeLanguage(l).then(() => {
      localStorage.setItem('lng', l)
    })
  }
  return (
    <>
      {LANGUAGES.map(lng => (
        <LanguageButton
          key={`lng-${lng}`}
          selected={lng === i18n.language}
          onClick={() => changeLanguage(lng)}
        >
          {lng}
        </LanguageButton>
      ))}
    </>
  )
}

export default function Profile() {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    profile,
    saveProfileParams,
    getPhoneCode,
    changePhone,
    verifyPhoneCode,
    hasMobileAuth,
    removeMobileAccount,
  } = useAuthentication()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [email, setEmail] = useState(profile?.[PARAM_EMAIL].value || '')
  const [phone, setPhone] = useState(profile?.[PARAM_PHONE].value || '')
  const [phoneConfirmationCode, setPhoneConfirmationCode] = useState('')
  const [address, setAddress] = useState(profile?.[PARAM_ADDRESS].value || '')
  const [agreeTerms, setAgreeTerms] = useState(true)
  const [enablePhoneVerification, setEnablePhoneVerification] = useState(false)
  const [warningPhone, setWarningPhone] = useState(null)
  const [selectedChildData, setSelectedChildData] = useState()
  const [
    warningPhoneConfirmationCode,
    setWarningPhoneConfirmationCode,
  ] = useState(null)

  const [selectedChild, setSelectedChild] = useState(0)

  const { data: children, refetch } = useQuery(GET_CHILDREN, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (children?.children?.listChildren) {
      const child = children.children.listChildren.find(
        child => child.personCode === selectedChild,
      )
      setSelectedChildData(child)
    }
  }, [selectedChild, children])

  const handleChildChange = event => {
    setSelectedChild(event.target.value)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    const params = []
    const _email = email.trim()
    if (profile[PARAM_EMAIL].value !== _email && profile[PARAM_EMAIL].canEdit) {
      params.push({ key: PARAM_EMAIL, value: _email })
    }
    const _phone = phone.trim()
    if (profile[PARAM_PHONE].value !== _phone && profile[PARAM_PHONE].canEdit) {
      params.push({ key: PARAM_PHONE, value: _phone })
    }
    const _address = address.trim()
    if (
      profile[PARAM_ADDRESS].value !== _address &&
      profile[PARAM_ADDRESS].canEdit
    ) {
      params.push({ key: PARAM_ADDRESS, value: _address })
    }
    if (
      (profile[PARAM_AGREE_PORTAL_TERMS].value === 'true') !== agreeTerms &&
      profile[PARAM_AGREE_PORTAL_TERMS].canEdit
    ) {
      params.push({
        key: PARAM_AGREE_PORTAL_TERMS,
        value: agreeTerms ? 'true' : 'false',
      })
    }
    if (params.length > 0) {
      setLoading(true)
      setError(null)
      saveProfileParams(params)
        .catch(() => {
          setEmail(profile?.[PARAM_EMAIL].value)
          setPhone(profile?.[PARAM_PHONE].value)
          setAddress(profile?.[PARAM_ADDRESS].value)
          setAgreeTerms(profile?.[PARAM_AGREE_PORTAL_TERMS].value === 'true')
        })
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          setError(t('error'))
        })
    }
  }

  const onVerifyButtonClick = () => {
    setWarningPhone(null)
    getPhoneCode(phone)
      .then(() => {
        setEnablePhoneVerification(true)
      })
      .catch(() => {
        setWarningPhone(t('login.error-phone-number'))
      })
  }

  const onConfirmButtonClick = () => {
    setWarningPhone(null)
    if (!hasMobileAuth()) {
      verifyPhoneCode(phone, phoneConfirmationCode)
        .then(() => {
          setEnablePhoneVerification(false)
        })
        .catch(() => {
          setWarningPhoneConfirmationCode(t('profile.error-auth-code'))
        })
    } else {
      changePhone(phone, phoneConfirmationCode)
        .then(() => {
          setEnablePhoneVerification(false)
        })
        .catch(() => {
          setWarningPhoneConfirmationCode(t('profile.error-auth-code'))
        })
    }
  }

  return (
    <Box maxWidth="560px">
      <Section
        title={t('Menu item my profile')}
        subtile={t('My profile description')}
      >
        {/* eslint-disable-next-line react/jsx-no-bind */}
        <form onSubmit={handleSubmit}>
          <GridContainer>
            <GridTitle>{t('Name profile')}</GridTitle>
            <GridValue>
              {`${profile[PARAM_FIRST_NAME].value} ${profile[PARAM_LAST_NAME].value}`.toUpperCase()}
            </GridValue>
          </GridContainer>
          <GridContainer>
            <GridTitle>{t('Personal ID profile')}</GridTitle>
            <GridValue>{profile[PARAM_PERSONAL_CODE].value}</GridValue>
          </GridContainer>
          <GridContainer>
            <GridTitle>
              <InputLabel htmlFor="email-input">
                {t('Email profile')}
              </InputLabel>
            </GridTitle>
            <GridValue>
              <InputBase
                id="email-input"
                fullWidth
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled={loading || !profile[PARAM_EMAIL].canEdit}
              />
            </GridValue>
          </GridContainer>
          <GridContainer>
            <GridTitle>
              <InputLabel htmlFor="phone-input">
                {t('profile-phone-number')}
              </InputLabel>
            </GridTitle>
            <GridValue>
              <InputBase
                id="phone-input"
                fullWidth
                value={phone}
                onChange={e => setPhone(e.target.value)}
                disabled={loading || enablePhoneVerification || hasMobileAuth()}
                style={{ marginRight: '5px' }}
              />

              {(hasMobileAuth() && (
                <Button
                  style={{ width: '235px' }}
                  onClick={() => removeMobileAccount()}
                >
                  {t('profile.delete-phone')}
                </Button>
              )) || (
                <Button
                  disabled={
                    loading ||
                    (hasMobileAuth() && profile[PARAM_PHONE].value === phone) ||
                    phone.length !== PHONE_NUMBER_LENGTH ||
                    enablePhoneVerification
                  }
                  style={{ width: '235px' }}
                  onClick={onVerifyButtonClick}
                >
                  {t('profile.verify')}
                </Button>
              )}
            </GridValue>
            {warningPhone && (
              <div className={classes.warning}>{warningPhone}</div>
            )}
          </GridContainer>
          {enablePhoneVerification && (
            <GridContainer classes={classes}>
              <GridTitle>
                <InputLabel htmlFor="phone-verification-input">
                  {t('profile.verification-code')}
                </InputLabel>
              </GridTitle>
              <GridValue>
                <InputBase
                  id="phone-confirmation-code-input"
                  fullWidth
                  value={phoneConfirmationCode}
                  onChange={e => setPhoneConfirmationCode(e.target.value)}
                  disabled={loading}
                  style={{ marginRight: '5px' }}
                />
                <Button
                  disabled={loading}
                  style={{ width: '235px' }}
                  onClick={onConfirmButtonClick}
                >
                  {t('profile.confirm')}
                </Button>
              </GridValue>
              {warningPhoneConfirmationCode && (
                <div className={classes.warning}>
                  {warningPhoneConfirmationCode}
                </div>
              )}
            </GridContainer>
          )}
          <GridContainer>
            <GridTitle>
              <InputLabel htmlFor="address-input">
                {t('create-document.profile.address')}
              </InputLabel>
            </GridTitle>
            <GridValue>
              <InputBase
                id="address-input"
                fullWidth
                value={address}
                onChange={e => setAddress(e.target.value)}
                disabled={loading || !profile[PARAM_ADDRESS].canEdit}
              />
            </GridValue>
          </GridContainer>
          <GridContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreeTerms}
                  onChange={() => setAgreeTerms(!agreeTerms)}
                  disabled={
                    loading || !profile[PARAM_AGREE_PORTAL_TERMS].canEdit
                  }
                />
              }
              label={t('profile.terms')}
            />
          </GridContainer>
          <GridContainer>
            <GridTitle>{t('Lang profile')}</GridTitle>
            <GridValue container>
              <LanguageSwitch />
            </GridValue>
          </GridContainer>
          {error && <Error>{error}</Error>}
          <Box pt={4}>
            <Button
              fullWidth
              type="submit"
              disabled={loading || enablePhoneVerification}
            >
              {loading ? (
                <Box display="flex" alignItems="center">
                  <CircularProgress color="inherit" size={24} thickness={8} />
                </Box>
              ) : (
                t('Submit button profile')
              )}
            </Button>
          </Box>
        </form>
      </Section>
      {children && children?.children?.listChildren?.length > 0 && (
        <Section
          title={t('profile.children-title')}
          subtile={t('profile.children-subtitle')}
          className={classes.childrenSection}
        >
          <Select
            value={selectedChild}
            onChange={handleChildChange}
            className={classes.childSelect}
            variant="outlined"
            inputProps={{
              classes: {
                iconOutlined: classes.childSelectIconOutlined,
              },
            }}
          >
            <MenuItem value={0}>{t('profile.children-select-empty')}</MenuItem>
            {children.children.listChildren.map((child, i) => (
              <MenuItem
                key={i}
                value={child.personCode}
              >{`${child.name} ${child.surname}`}</MenuItem>
            ))}
          </Select>
          {selectedChildData && (
            <ProfileChild
              childData={selectedChildData}
              refetchChildren={() => refetch()}
            />
          )}
        </Section>
      )}
    </Box>
  )
}
