import React from 'react'
import gql from 'graphql-tag'
import {
  ApolloProvider as Provider,
  ApolloClient,
  createHttpLink,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client'
import { useConfig } from './SiauliaiConfigProvider'
import { setContext } from '@apollo/link-context'
import { SIAULIAI_AUTH_TOKEN } from './SiauliaiAuthenticationProvider'
import { RestLink } from 'apollo-link-rest'
import { cordovaHttpFetchImpl } from '../../utils/fetchUtils'
import { onError } from 'apollo-link-error'
import { useAppState } from '@mcity/siauliai-core/src/components/providers/AppStateProvider'

export default function SiauliaiApolloProvider({ children }) {
  const config = useConfig()
  const { setError } = useAppState()

  const cache = new InMemoryCache()

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      const errorIdList = []

      for (const error of graphQLErrors) {
        if (error.extensions && error.extensions.ID) {
          errorIdList.push(error.extensions.ID)
        }
      }
      if(errorIdList.length){
        setError({
          errorIdList,
        })
      }
    }

    if (networkError) {
      setError()
    }
  })

  const httpLink = createHttpLink({
    uri: config.graphqlUri,
  })

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(SIAULIAI_AUTH_TOKEN)
    if (token && token !== 'null') {
      return {
        headers: {
          ...headers,
          Authorization: `Token ${token}`,
        },
      }
    } else {
      return headers
    }
  })

  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([
      errorLink,
      new RestLink({
        endpoints: {
          news: 'https://www.siauliai.lt/wwwapi/api/v1/news/',
        },
        customFetch: cordovaHttpFetchImpl,
      }),
      authLink,
      httpLink,
    ]),
    typeDefs: gql`
      type AuthorizationUserParamInput {
        key: String!
        value: String!
        isEditable: Boolean!
      }
    `,
  })

  return client ? <Provider client={client}>{children}</Provider> : <></>
}
