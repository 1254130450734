import React from 'react'
import MuiPaper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import MuiTypography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import ButtonBase from '@material-ui/core/ButtonBase'
import { withStyles } from '@material-ui/core/styles'
import {
  Menu1Icon,
  Menu2Icon,
  Menu3Icon,
  Menu4Icon,
  Menu5Icon,
  Button,
  AdapterLink,
  UserName,
} from '../elements'
import PlainLink from '@mcity/siauliai-core/src/components/elements/PlainLink'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Paper = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    padding: `0 ${theme.spacing(2)}px`,
    '& > *': {
      padding: `${theme.spacing(2)}px 0`,
    },
  },
}))(MuiPaper)

const Typography = withStyles(theme => ({
  h1: {
    marginBottom: 20,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(30),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(17),
      lineHeight: theme.typography.pxToRem(20),
      margin: '30px 0 10px 0',
    },
  },
  h2: {
    marginBottom: 45,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(21),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(18),
      margin: 0,
    },
  },
  h3: {
    marginBottom: 15,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(15),
    textAlign: 'center',
  },
  h4: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(13),
    textAlign: 'center',
  },
}))(MuiTypography)

const LinkButton = withStyles(theme => ({
  root: {
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    border: '1px solid #FAAB00',
    borderRadius: 5,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(17),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
}))(ButtonBase)

const LINKS = [
  {
    h3: 'Menu item my profile',
    h4: 'My profile description',
    icon: props => <Menu1Icon width="55" height="70" {...props} />,
    url: '/my-profile',
  },
  {
    h3: 'Menu My e-cards',
    h4: 'My e-cards description',
    icon: props => (
      <Menu2Icon
        width="71"
        height="60"
        style={{ marginTop: '10px' }}
        {...props}
      />
    ),
    url: '/my-e-cards',
  },
  {
    h3: 'Manu My discounts',
    h4: 'My discounts description',
    icon: props => (
      <Menu3Icon
        width="65"
        height="49"
        style={{ marginTop: '15px' }}
        {...props}
      />
    ),
    url: '/my-discounts',
  },
  {
    h3: 'Menu My documents',
    h4: 'My docs description',
    icon: props => (
      <Menu4Icon
        width="59"
        height="61"
        style={{ marginTop: '5px' }}
        {...props}
      />
    ),
    url: '/my-documents',
  },
  // TODO: hidden temporarily by client's request (SAULI-57)
  // {
  //   h3: 'Menu order e-cards',
  //   h4: 'Order ecards desc',
  //   icon: props => (
  //     <Menu5Icon
  //       width="62"
  //       height="61"
  //       style={{ marginTop: '10px' }}
  //       {...props}
  //     />
  //   ),
  //   url: '/order-e-card',
  // },
]
export default function Home() {
  const location = useLocation()
  const { t } = useTranslation()
  return (
    <>
      <Hidden smUp>
        <Grid container spacing={1}>
          {LINKS.reduce((acc, { icon: Icon, url, h3 }, i) => {
            acc.push(
              <Grid key={i} item xs={6}>
                <LinkButton
                  component={AdapterLink}
                  to={url}
                  query={location.search}
                >
                  <Icon
                    width="42"
                    height="42"
                    style={{ margin: 0 }}
                    fill="#FAAB00"
                  />
                  <Box mt={2}>{t(h3)}</Box>
                </LinkButton>
              </Grid>,
            )
            return acc
          }, [])}
        </Grid>
      </Hidden>
      <Typography variant="h1">{t('Home title')}</Typography>
      <Typography variant="h2">
        {t('Home Description / Menu description')}
      </Typography>
      <Hidden xsDown>
        <Grid container spacing={2}>
          {LINKS.map(({ h3, h4, icon: Icon, url }, i) => (
            <Grid key={i} item sm={4} md>
              <PlainLink
                to={location.search ? `${url}/${location.search}` : url}
              >
                <Paper>
                  <div>
                    <Icon fill="#FAAB00" />
                  </div>
                  <Box maxWidth="175px">
                    <Typography variant="h3">
                      {i ? (
                        t(h3)
                      ) : (
                        <UserName>
                          {({ firstName, lastName }) =>
                            `${firstName.value} ${lastName.value}`
                          }
                        </UserName>
                      )}
                    </Typography>
                    <Typography variant="h4">{t(h4)}</Typography>
                  </Box>
                  <Box alignSelf="stretch">
                    <Button fullWidth to={url} query={location.search}>
                      {t('Open')}
                    </Button>
                  </Box>
                </Paper>
              </PlainLink>
            </Grid>
          ))}
        </Grid>
      </Hidden>
    </>
  )
}
