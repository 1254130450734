import React from 'react'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'
import {
  ArrowDown,
  ArrowRightIcon,
  Container,
  Content,
  Footer,
  Header,
  LoginStepOneIcon,
  LoginStepThreeIcon,
  LoginStepTwoIcon,
} from '../elements'
import VIISPWebLoginButton from '@mcity/siauliai-core/src/components/elements/authentication/VIISPWebLoginButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  content: {
    overflow: 'hidden',
    background:
      'radial-gradient(circle, rgba(250,188,7,1) 0%, rgba(248,163,5,1) 100%)',
  },
  rounded: {
    padding: '440px 80px 0 80px',
    margin: '-320px -80px 0 -80px',
    height: 640,
    borderRadius: '0 0 50% 50%',
    backgroundColor: theme.palette.common.white,
    boxShadow: ' -4px 4px 12px 1px rgba(217,142,0,0.4)',
    [theme.breakpoints.down('xs')]: {
      padding: '395px 80px 0 80px',
    },
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading1: {
    maxWidth: '520px',
    margin: '24px 0 16px 0',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(34),
    lineHeight: 1,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '42px 0 16px 0',
      maxWidth: 'calc(100vw - 32px)',
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(26),
    },
  },
  heading2: {
    maxWidth: '520px',
    margin: 0,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.2,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vw - 32px)',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(18),
    },
  },
  main: {
    margin: '64px 0 64px 0',
    [theme.breakpoints.down('xs')]: {
      margin: 32,
    },
  },
  steps: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  step: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
    },
  },
  stepArrowRight: {
    alignSelf: 'center',
  },
  stepArrowDown: {
    display: 'flex',
    justifyContent: 'center',
  },
  stepIcon: {
    [theme.breakpoints.down('xs')]: {
      flexShrink: 0,
      width: '120px',
    },
  },
  stepText: {
    maxWidth: '250px',
    marginTop: '20px',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    '&::first-letter': {
      color: 'white',
      backgroundColor: 'black',
      padding: '2px 8px',
      borderRadius: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
}))

export default function Login() {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Content className={classes.content}>
        <Header />
        <div className={classes.rounded}>
          <div className={classes.heading}>
            <h1 className={classes.heading1}>{t('Home title')}</h1>
            <h2 className={classes.heading2}>
              {t('Home Description / Menu description')}
            </h2>
          </div>
        </div>
        <Container>
          <main className={classes.main}>
            <Hidden smUp>
              <div className={classes.step}>
                <div className={classes.stepIcon}>
                  <LoginStepOneIcon
                    width="85"
                    height="101"
                    fill="white"
                    viewBox="45 0 282.3007 445.6"
                  />
                </div>
                <div className={classes.stepText}>{`1 ${t('Step one')}`}</div>
              </div>
              <div className={classes.stepArrowDown}>
                <ArrowDown width="32" height="17" fill="white" />
              </div>
              <div className={classes.step}>
                <div className={classes.stepIcon}>
                  <LoginStepTwoIcon width="65" height="101" fill="white" />
                </div>
                <div className={classes.stepText}>{`2 ${t('step-two')}`}</div>
              </div>
              <div className={classes.stepArrowDown}>
                <ArrowDown width="32" height="17" fill="white" />
              </div>
              <div className={classes.step}>
                <div className={classes.stepIcon}>
                  <LoginStepThreeIcon width="77" height="87" fill="white" />
                </div>
                <div className={classes.stepText}>{`3 ${t('Step three')}`}</div>
              </div>
              <Box my="30px">
                <VIISPWebLoginButton />
              </Box>
            </Hidden>
            <Hidden xsDown>
              <div className={classes.steps}>
                <div className={classes.step}>
                  <LoginStepOneIcon width="92" height="145" fill="white" />
                  <div className={classes.stepText}>{`1 ${t('Step one')}`}</div>
                </div>
                <div className={classes.stepArrowRight}>
                  <ArrowRightIcon width="17" height="32" />
                </div>
                <div className={classes.step}>
                  <LoginStepTwoIcon width="110" height="166" fill="white" />
                  <div className={classes.stepText}>{`2 ${t('step-two')}`}</div>
                </div>
                <div className={classes.stepArrowRight}>
                  <ArrowRightIcon width="17" height="32" />
                </div>
                <div className={classes.step}>
                  <LoginStepThreeIcon width="142" height="128" fill="white" />
                  <div className={classes.stepText}>
                    {`3 ${t('Step three')}`}
                  </div>
                </div>
              </div>
              <Box display="flex" justifyContent="center" my="50px">
                <VIISPWebLoginButton />
              </Box>
            </Hidden>
          </main>
        </Container>
      </Content>
      <Footer />
    </>
  )
}
