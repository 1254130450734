import React, {useState, useEffect} from 'react'
import {useMutation} from "@apollo/client";
import Error from '../../elements/Error'
import {CREATE_VIISP_AUTH_TICKET} from "../../../graphql/mutation";
import Button from '../../../../../mcity-siauliai-app/src/components/elements/Button'
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from 'react-router-dom';

function getRedirectUrl(from) {
    const base = `${location.protocol}//${location.hostname}:${location.port}/login/callback`
    if (from && from.pathname !== '/login' && from.pathname !== '/') {
        return `${base}?redirectTo=${from.pathname + from.search}`
    }
    return base
}

export function withVIISPAuthTicket(Component) {
    return function (props) {
        const { state } = useLocation();
        const [showError, setShowError] = useState(false)
        const [createAuthTicket, {data, loading, error, called}] = useMutation(
            CREATE_VIISP_AUTH_TICKET,
        )
        useEffect(() => {
            if (!called) {
                createAuthTicket({
                    variables: {
                        input: {
                            redirectUrl: getRedirectUrl(state?.from),
                        },
                    },
                })
                    .then(() => setShowError(false))
                    .catch(() => setShowError(true))
            }
        }, [createAuthTicket, called])

        if (error || showError) {
            return <Error>{error.message}</Error>
        }
        if (loading || !data?.viisp?.createAuthTicket) {
            return (
                <Button type="submit" size="large" fullWidth={false}>
                    <CircularProgress color="inherit" size={16} thickness={8}/>
                </Button>
            )
        }

        return <Component ticket={data.viisp.createAuthTicket.ticket} url={data.viisp.createAuthTicket.url} {...props} />
    }
}
