import { withStyles } from '@material-ui/core/styles'
import MuiInputLabel from '@material-ui/core/InputLabel'

const InputLabel = withStyles(theme => ({
  root: {
    fontWeight: 'inherit',
    fontSize: 'inherit',
    color: 'inherit',
  },
}))(MuiInputLabel)

export default InputLabel


