import React from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100vw',
    minHeight: 'calc(100vh - 155px)',
    backgroundColor: '#e9ebee',
    paddingBottom: 32,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: 'transparent',
    },
  },
})

function Content({ classes, className, children }) {
  return <div className={clsx(classes.root, className)}>{children}</div>
}

export default withStyles(styles, { name: 'Content' })(Content)
