import React from 'react'

export default function LoginStepTwoIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      enableBackground="new 0 0 323.4 492.7"
      viewBox="0 0 323.4 492.7"
      {...props}
    >
      <path
        d="M272.1 276.9H51.3c-10.1 0-18.2 8.2-18.2 18.2v30.2c0 10.1 8.2 18.2 18.2 18.2h220.8c10.1 0 18.2-8.2 18.2-18.2v-30.2c0-10-8.2-18.2-18.2-18.2zm10.2 48.5c0 5.6-4.6 10.2-10.2 10.2H51.3c-5.6 0-10.2-4.6-10.2-10.2v-30.2c0-5.6 4.6-10.2 10.2-10.2h220.8c5.6 0 10.2 4.6 10.2 10.2v30.2zM272.1 190.4H51.3c-10.1 0-18.2 8.2-18.2 18.2v30.2c0 10.1 8.2 18.2 18.2 18.2h220.8c10.1 0 18.2-8.2 18.2-18.2v-30.2c0-10-8.2-18.2-18.2-18.2zm10.2 48.5c0 5.6-4.6 10.2-10.2 10.2H51.3c-5.6 0-10.2-4.6-10.2-10.2v-30.2c0-5.6 4.6-10.2 10.2-10.2h220.8c5.6 0 10.2 4.6 10.2 10.2v30.2z"
        className="st0"
      />
      <path
        d="M230.8 307.7c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zM207.7 306.7c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6 3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6z"
        className="st0"
      />
      <circle cx="184.7" cy="310.3" r="4.7" className="st0" />
      <path
        d="M167 308c-.1-.3-.3-.7-.5-1-.2-.3-.4-.6-.7-.8l-.4-.4c-1-.8-2.3-1.3-3.7-1.3-1.4 0-2.7.5-3.7 1.3-.1.1-.3.2-.4.4-.3.3-.5.5-.7.8-.2.3-.4.6-.5 1-.1.3-.3.7-.3 1.1s-.1.8-.1 1.2 0 .8.1 1.2c.1.4.2.7.3 1.1.2.5.5 1 .9 1.4.1.1.2.3.4.4.3.3.5.5.8.7.3.2.6.4 1 .5.3.1.7.3 1.1.3s.8.1 1.2.1c.4 0 .8 0 1.2-.1s.7-.2 1.1-.3c.3-.1.7-.3 1-.5.3-.2.6-.4.8-.7l.4-.4c.4-.4.6-.9.9-1.4.1-.3.3-.7.3-1.1.1-.4.1-.8.1-1.2s0-.8-.1-1.2-.4-.7-.5-1.1zM92.6 307.7c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5c.1-1.3-1.1-2.5-2.5-2.5zM115.6 306.7c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6 3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6zM138.7 305.6c-2.6 0-4.7 2.1-4.7 4.7s2.1 4.7 4.7 4.7 4.7-2.1 4.7-4.7c-.1-2.6-2.2-4.7-4.7-4.7zM230.8 221.2c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5c0-1.3-1.1-2.5-2.5-2.5zM207.7 220.2c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6 3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6z"
        className="st0"
      />
      <circle cx="184.7" cy="223.8" r="4.7" className="st0" />
      <path
        d="M166.7 221l-.6-.9c-.2-.3-.5-.5-.8-.8-1-.8-2.3-1.3-3.7-1.3-1.4 0-2.7.5-3.7 1.3-.3.2-.5.5-.8.8l-.6.9c-.4.8-.7 1.7-.7 2.7 0 1 .3 1.9.7 2.7.1.2.2.3.3.5 1 1.5 2.8 2.5 4.8 2.5s3.7-1 4.8-2.5c.1-.2.2-.3.3-.5.4-.8.7-1.7.7-2.7 0-.9-.2-1.9-.7-2.7zM92.6 221.2c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zM115.6 220.2c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6 3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6zM138.7 219.1c-2.6 0-4.7 2.1-4.7 4.7s2.1 4.7 4.7 4.7 4.7-2.1 4.7-4.7c-.1-2.6-2.2-4.7-4.7-4.7z"
        className="st0"
      />
      <path
        d="M306.7 80.5h-60.4C244.1 35.8 207 0 161.7 0S79.3 35.8 77.1 80.5H16.7C7.5 80.5 0 89.4 0 100.3V473c0 10.9 7.5 19.8 16.7 19.8h290c9.2 0 16.7-8.9 16.7-19.8V100.3c0-10.9-7.5-19.8-16.7-19.8zM161.7 8c42.3 0 76.7 34.4 76.7 76.7 0 26.7-13.7 50.2-34.4 63.9v-35.5c0-9.8-7.7-17.7-17.4-18.1h-.1c-1.8 0-10.7-.5-12.6-10.7 6-4.6 10.1-12.2 11.4-21.1.2-.3.4-.6.5-.9.5-1.3 1-2.6 1.3-3.9 1.6-6.3 1.1-12.9-1.4-18.5-2.6-5.9-7.2-10-12.8-11.6-1.1-.3-2.2-.5-3.4-.6-3.1-1.5-6.5-2.3-9.9-2.3-13.4 0-24.3 11.7-24.3 26 0 3.7.7 7.3 2.1 10.7.1.3.3.7.6.9.8 6 3 11.5 6.3 16 1.5 2.1 3.3 3.9 5.2 5.3-1.9 10.2-10.8 10.7-12.6 10.7h-.1c-9.8.4-17.4 8.3-17.4 18.1v35.5C98.7 134.8 85 111.3 85 84.7 85 42.4 119.4 8 161.7 8zm4.9 79.9c1 3.8 3.2 7.1 6.1 9.8l-11 15.7-11-15.7c2.9-2.6 5-6 6.1-9.7 3.2.8 6.6.8 9.8-.1zm6.3-44.8h-.3c-1.8 0-3.4 1.1-4.1 2.8-.2.5-.5 1-1 1.3-.5.4-1.1.6-1.8.6h-16.5c-2.1 0-4.1.5-5.9 1.6 1-9 7.9-16.1 16.3-16.1 2.4 0 4.8.6 7 1.8.5.3 1.1.4 1.6.4.8 0 1.6.2 2.4.4 6.3 1.8 10.1 9.2 9.3 17.3-1.4-2-2.5-4.3-3.1-6.7-.3-1.9-1.9-3.3-3.9-3.4zm-23.6 12.5h16.5c2.1 0 4-.6 5.7-1.6 1.5 3 3.6 5.8 6 8.1-.7 5.1-2.6 9.7-5.4 13.2-2.9 3.6-6.6 5.5-10.4 5.5-8.8 0-16-10.3-16.1-22.9.7-1.4 2.1-2.3 3.7-2.3zm-22.1 94.8v-37.3c0-5.7 4.6-10.2 10.2-10.2.4 0 3.2 0 6.5-1.2l12.2 17.4c1.3 1.8 3.3 2.9 5.5 2.9s4.3-1.1 5.5-2.9l12.2-17.4c3.1 1.1 6.5 1.2 6.5 1.2 5.7 0 10.2 4.6 10.2 10.2v37.3l1.1 2.2c-10.6 5.6-22.7 8.8-35.6 8.8-12.8 0-24.9-3.2-35.6-8.8l1.3-2.2zM315.4 473c0 6.4-4 11.8-8.7 11.8h-290C12 484.7 8 479.3 8 473V100.3c0-6.4 4-11.8 8.7-11.8h60.4c2 44.9 39.2 80.8 84.6 80.8s82.6-35.9 84.6-80.8h60.4c4.7 0 8.7 5.4 8.7 11.8V473z"
        className="st0"
      />
      <path
        d="M286.3 391.8H37.1c-2.2 0-4 1.8-4 4s1.8 4 4 4h249.3c2.2 0 4-1.8 4-4s-1.9-4-4.1-4zM161.7 417.4c-13.3 0-24.2 10.8-24.2 24.2s10.8 24.2 24.2 24.2c13.3 0 24.2-10.8 24.2-24.2 0-13.3-10.9-24.2-24.2-24.2zm0 38.5c-7.9 0-14.3-6.4-14.3-14.3s6.4-14.3 14.3-14.3 14.3 6.4 14.3 14.3-6.4 14.3-14.3 14.3z"
        className="st0"
      />
    </svg>
  )
}
