import React from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  root: {
    margin: 'auto',
    maxWidth: 1160,
  },
})

function Container({ classes, className, children }) {
  return <div className={clsx(classes.root, className)}>{children}</div>
}

export default withStyles(styles, { name: 'Content' })(Container)
