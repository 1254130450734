import { withStyles } from '@material-ui/core/styles'
import MuiInputBase from '@material-ui/core/InputBase'

export const InputBase = withStyles(theme => ({
  root: {
    border: '1px solid #FAAB00',
    borderRadius: 4,
    paddingLeft: 8,
    fontWeight: 'inherit',
    fontSize: 'inherit',
  },
  error: {
    border: '1px solid red',
  },
}))(MuiInputBase)

export default InputBase


