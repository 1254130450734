import React, { useRef, useState } from 'react'
import { Button } from '../index'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { useMutation } from '@apollo/client'
import { CREATE_ATTACHMENT } from '../../../graphql/mutation'
import DocumentAttachmentRemove from './DocumentAttachmentRemove'
import { withStyles } from '@material-ui/core/styles'
import { useConfig } from '@mcity/siauliai-core/src/components/providers/SiauliaiConfigProvider'
import { useAuthentication } from '@mcity/siauliai-core/src/components/providers'
import { useTranslation } from 'react-i18next'

const Container = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))(Box)

export default function DocumentAttachmentUpload({
  attachmentConfig,
  attachment,
  setAttachment,
}) {
  const { t } = useTranslation()
  const config = useConfig()
  const { authToken } = useAuthentication()
  const fileInputRef = useRef(null)

  const [errorMessage, setErrorMessage] = useState('')
  const [uploading, setUploading] = useState(false)

  const [createAttachment, { loading }] = useMutation(CREATE_ATTACHMENT)

  const onUploadButtonClick = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click()
    }
  }

  const onFileChange = () => {
    uploadFileAndCreateAttachment()
  }

  const uploadFileAndCreateAttachment = () => {
    const data = new FormData()
    data.append('file', fileInputRef.current.files[0])
    data.append('file_type', 'document')
    setUploading(true)
    fetch(config.uploadUrl, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: authToken ? `Token ${authToken}` : '',
      },
    })
      .then(response => {
        setUploading(false)
        if (response.status !== 200) {
          throw new Error(response.status.toString())
        }
        return response.text()
      })
      .then(uploadedId => {
        if (uploadedId && uploadedId.indexOf('Error') === -1) {
          return createAttachment({
            variables: {
              objectId: -1,
              module: 'siauliai',
              uploadId: uploadedId,
            },
          })
        } else {
          throw new Error('Upload error')
        }
      })
      .then(({ data }) => {
        if (data?.attachments?.addAttachment) {
          setErrorMessage(null)
          setAttachment(data.attachments.addAttachment)
          setUploading(false)
        } else {
          throw new Error('Attachment error')
        }
      })
      .catch(() => {
        setAttachment(null)
        setErrorMessage(t('create-document.attachment.error'))
        setUploading(false)
      })
  }

  if (uploading || loading) {
    return <CircularProgress color="secondary" />
  }
  const { formUrl, extensions } = attachmentConfig
  const acceptExtensions =
    extensions.length > 0 ? extensions.map(ext => `.${ext}`).join(',') : '*'
  return (
    <Container>
      <span style={{ color: 'red', paddingRight: '5px' }}>{errorMessage}</span>
      {attachment ? (
        <>
          {attachment.upload.name}
          <DocumentAttachmentRemove
            attachment={attachment}
            setAttachment={setAttachment}
          />
        </>
      ) : (
        <>
          {formUrl && (
            <a
              href={formUrl}
              rel="noreferrer"
              target="_blank"
              style={{ marginRight: '5pt' }}
            >
              {t('create-document.attachment.form')}
            </a>
          )}
          <input
            style={{ display: 'none' }}
            type="file"
            ref={fileInputRef}
            onChange={onFileChange}
            accept={acceptExtensions}
          />
          <label htmlFor="file-attachment">
            <Button onClick={onUploadButtonClick}>
              <AttachFileIcon style={{ paddingRight: '5px' }} />
              {t('create-document.attachment.browse')}
            </Button>
          </label>
        </>
      )}
    </Container>
  )
}
