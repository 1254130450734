import React from 'react'
import { makeStyles, Button as MuiButton } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    backgroundColor: props =>
      props.primary ? theme.palette.primary.main : '#000000',
    borderRadius: 4,
    height: 45,
  },
}))

export default function Button({ children, primary, ...props }) {
  const classes = useStyles({ primary: primary })
  return (
    <MuiButton
      fullWidth
      variant="contained"
      className={classes.root}
      {...props}
    >
      {children}
    </MuiButton>
  )
}
