import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import lt from './lt/translation.json'
import en from './en/translation.json'
import ru from './ru/translation.json'
import lv from './lv/translation.json'

export const LANGUAGES = ['lt', 'lv', 'en', 'ru']

i18n.use(initReactI18next).init({
  resources: {
    lt: {
      translation: lt,
    },
    en: {
      translation: en,
    },
    ru: {
      translation: ru,
    },
    lv: {
      translation: lv,
    },
  },
  lng: localStorage.getItem('lng') || 'lt',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
