import React from 'react'
import {
  createTheme,
  ThemeProvider as Provider,
} from '@material-ui/core/styles'

let theme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },

  palette: {
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#FFECA6',
    },
    secondary: {
      main: 'rgb(250, 171, 0)',
    },
    text: {
      primary: '#000000',
      secondary: '#808080',
    },
  },
})

theme = {
  ...theme,
  overrides: {
    MuiCssBaseline: {
      a: {
        textDecoration: 'none',
        color: 'inherit',
      },
    },
    MuiToolbar: {
      gutters: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
      },
    },
  },
}

export default function SiauliaiPortalThemeProvider({ children }) {
  return <Provider theme={theme}>{children}</Provider>
}
