import React from 'react'

export default function UserIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.9 96.4" {...props}>
      <circle cx="48" cy="48" r="48" fill="#faab00" />
      <circle cx="48" cy="48.4" r="48" fill="none" />
      <path
        fill="#ffeda6"
        d="M75.6 45.7c2.2-9.1 2.9-23-7.2-27.3 0 0-9.1-11.5-27.9-7.2C21.6 15.6 20.2 27 20.7 38c.1 2.8.4 5.3.6 7.5-2.2 1.1-3.7 3.6-3.7 6.4 0 3.9 2.8 7 6.2 7 .6 0 1.1-.1 1.7-.3 2 7.7 6.6 14.7 16.5 16.8V80c-7.7 1.3-15.1 4.7-19.7 8.4 7.4 4.7 16.2 7.4 25.6 7.4 9.6 0 18.5-2.8 26-7.7-4.4-3.4-11.2-6.6-18.3-8-.1 0-.2 0-.3-.1-.3-.1-.6-.1-.8-.1v-4.6C64.4 73.2 69 66.2 71 58.5c.5.2 1.1.3 1.7.3 3.4 0 6.2-3.1 6.2-7 0-2.6-1.3-4.9-3.3-6.1z"
      />
    </svg>
  )
}
