import React from 'react'
import { useMutation } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteIcon from '@material-ui/icons/Delete'
import { DELETE_ATTACHMENT } from '../../../graphql/mutation'

export default function DocumentAttachmentRemove({
  attachment,
  setAttachment,
}) {
  const [deleteAttachment, { loading }] = useMutation(DELETE_ATTACHMENT)

  const onUploadDelete = () => {
    deleteAttachment({ variables: { id: attachment.id } }).then(({ data }) => {
      if (data?.attachments?.deleteAttachment) {
        setAttachment(null)
      }
    })
  }
  if (loading) {
    return <CircularProgress color="secondary" />
  }
  return <DeleteIcon style={{ cursor: 'pointer' }} onClick={onUploadDelete} />
}
