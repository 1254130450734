import React from 'react'

export default function ArrowRightIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 202.9 377.2"
      viewBox="0 0 202.9 377.2"
      {...props}
    >
      <path
        fill="#fff"
        d="M14.3 0L0 14.3l174.3 174.3L0 362.8l14.3 14.4 174.3-174.3 14.3-14.3z"
      />
    </svg>
  )
}
