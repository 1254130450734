import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { hot } from 'react-hot-loader/root'
import { ThemeProvider } from './components/providers'
import {
  Login,
  Home,
  MyDocuments,
  Profile,
  MyCards,
  Logout,
  OrderCard,
} from './components/views'
import { Main, Sidebar } from './components/elements'
import DocumentServices from './components/views/documents/DocumentServices'
import {
  SiauliaiConfigProvider,
  SiauliaiApolloProvider,
  SiauliaiAuthenticationProvider,
  useAuthentication,
} from '@mcity/siauliai-core/src/components/providers'
import VIISPLoginReactCallback from '../../mcity-siauliai-core/src/components/elements/authentication/VIISPLoginReactCallback'
import SiauliaiPortalDiscounts from './components/views/SiauliaiPortalDiscounts'
import SiauliaiPreloader from '@mcity/siauliai-core/src/components/elements/SiauliaiPreloader'
import { AppStateProvider } from '@mcity/siauliai-core/src/components/providers/AppStateProvider'
import { OfflineNotice } from '@mcity/siauliai-core/src/components/elements/error/OfflineNotice'

function NotFound() {
  return <Redirect to="/" noThrow />
}

function AuthenticatedRoute({ children, viispOnly, ...rest }) {
  const {
    authToken,
    hasVIISPAuth,
    isAuthContextAvailable,
  } = useAuthentication()
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!authToken || (viispOnly && !hasVIISPAuth())) {
          return (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          )
        } else {
          return isAuthContextAvailable ? children : <SiauliaiPreloader />
        }
      }}
    />
  )
}

function App() {
  return (
    <SiauliaiConfigProvider>
      <Router>
        <AppStateProvider>
          <ThemeProvider>
            <CssBaseline />
            <SiauliaiApolloProvider>
              <SiauliaiAuthenticationProvider>
                <Switch>
                  <Route exact path="/login">
                    <Login />
                  </Route>
                  <Route exact path="/logout">
                    <Logout />
                  </Route>
                  <Route exact path="/login/callback">
                    <VIISPLoginReactCallback />
                  </Route>
                  <AuthenticatedRoute exact path="/">
                    <Main>
                      <Home />
                    </Main>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path="/my-profile">
                    <Main>
                      <Sidebar>
                        <Profile />
                      </Sidebar>
                    </Main>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path="/my-e-cards">
                    <Main>
                      <Sidebar>
                        <MyCards />
                      </Sidebar>
                    </Main>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path="/my-documents">
                    <Main>
                      <Sidebar>
                        <MyDocuments />
                      </Sidebar>
                    </Main>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/services/:id?">
                    <Main>
                      <Sidebar>
                        <DocumentServices />
                      </Sidebar>
                    </Main>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path="/my-discounts">
                    <Main>
                      <Sidebar>
                        <SiauliaiPortalDiscounts />
                      </Sidebar>
                    </Main>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path="/order-e-card">
                    <Main>
                      <Sidebar>
                        <OrderCard />
                      </Sidebar>
                    </Main>
                  </AuthenticatedRoute>
                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
              </SiauliaiAuthenticationProvider>
              <OfflineNotice />
            </SiauliaiApolloProvider>
          </ThemeProvider>
        </AppStateProvider>
      </Router>
    </SiauliaiConfigProvider>
  )
}

export default hot(App)
