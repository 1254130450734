const endpoint = process.env.ENDPOINT || 'https://mano.siauliai.lt'
const cityUrl = "https://siauliai.lt"
const cityCenter = [23.313259265231284, 55.934474552025485]
const mapBoxKey =
  'pk.eyJ1IjoibW9iaWxseSIsImEiOiJja2oxYXRuZnAwajV3MnpsZ3k0djlkdm1wIn0.eCI8FPaqg47pOqnk4-MQpg'
const googleMapsApiKey = 'AIzaSyDW7jQ123nyxKt1gOB54RNDo3VPYpO_9WI'

module.exports = {
  graphqlUri: `${endpoint}/graphql`,
  uploadUrl: `${endpoint}/rest/upload_file`,
  downloadUrl: `${endpoint}/rest/download_file`,
  downloadWithTokenUrl: `${endpoint}/rest/download_file_with_token`,
  cityCenter,
  mapBoxKey,
  googleMapsApiKey,
  cityUrl,
  lang: 'lt',
  endpoint,
}
