import MuiButton from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

const Button = withStyles(theme => ({
  root: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: 'black',
    },
    '&.Mui-disabled': {
      color: 'black',
      backgroundColor: '#D9D9D9',
    },
  },
  sizeLarge: {
    padding: '21px 41px',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
  },
}))(MuiButton)

export default Button
