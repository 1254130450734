import React from 'react'

export default function LoginStepOneIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      viewBox="0 0 282.3007 445.6"
      {...props}
    >
      <g id="g265">
        <path
          id="path247"
          fill="#fff"
          d="M48 146.4h72c2.2 0 4-1.8 4-4s-1.8-4-4-4H48c-2.2 0-4 1.8-4 4s1.8 4 4 4z"
          className="st0"
        />
        <path
          id="path249"
          fill="#fff"
          d="M48 181.5h186.4c2.2 0 4-1.8 4-4s-1.8-4-4-4H48c-2.2 0-4 1.8-4 4s1.8 4 4 4z"
          className="st0"
        />
        <path
          id="path251"
          fill="#fff"
          d="M48 216.5h147.6c2.2 0 4-1.8 4-4s-1.8-4-4-4H48c-2.2 0-4 1.8-4 4s1.8 4 4 4z"
          className="st0"
        />
        <path
          id="path253"
          fill="#fff"
          d="M234.4 243.6H48c-2.2 0-4 1.8-4 4s1.8 4 4 4h186.4c2.2 0 4-1.8 4-4s-1.8-4-4-4z"
          className="st0"
        />
        <path
          id="path255"
          fill="#fff"
          d="M234.4 278.6H48c-2.2 0-4 1.8-4 4s1.8 4 4 4h186.4c2.2 0 4-1.8 4-4s-1.8-4-4-4z"
          className="st0"
        />
        <path
          id="path257"
          fill="#fff"
          d="M258.4 0H24C10.8 0 0 10.8 0 24v397.6c0 13.2 10.8 24 24 24h234.3c13.2 0 24-10.8 24-24V24c.1-13.2-10.7-24-23.9-24zM8 79.3h266.4v280.2H8zM8 24c0-8.8 7.2-16 16-16h234.3c8.8 0 16 7.2 16 16v47.3H8zm250.4 413.6H24c-8.8 0-16-7.2-16-16v-54.1h266.4v54.1c0 8.8-7.2 16-16 16z"
          className="st0"
        />
        <path
          id="path259"
          fill="#fff"
          d="M141.2 380.4c-13.1 0-23.8 10.7-23.8 23.8 0 13.1 10.6 23.8 23.8 23.8 13.1 0 23.8-10.7 23.8-23.8 0-13.1-10.7-23.8-23.8-23.8zm0 37.9c-7.8 0-14.1-6.3-14.1-14.1s6.3-14.1 14.1-14.1 14.1 6.3 14.1 14.1-6.3 14.1-14.1 14.1z"
          className="st0"
        />
        <path
          id="path261"
          fill="#fff"
          d="M182.9 30h-66c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9h66c2.7 0 4.9-2.2 4.9-4.9s-2.2-4.9-4.9-4.9z"
          className="st0"
        />
        <path
          id="path263"
          fill="#fff"
          d="M99.5 30c-1.3 0-2.5.5-3.4 1.4-.9.9-1.4 2.2-1.4 3.4 0 1.3.5 2.5 1.4 3.4.9.9 2.2 1.4 3.4 1.4 1.3 0 2.5-.5 3.4-1.4.9-.9 1.4-2.2 1.4-3.4 0-1.3-.5-2.5-1.4-3.4-.9-.9-2.2-1.4-3.4-1.4z"
          className="st0"
        />
      </g>
    </svg>
  )
}
