import React from 'react'
import Paper from '@material-ui/core/Paper'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'
import { Navigation } from '../elements'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: 'calc(100vh - 340px)',
  },
  sidebar: {
    flex: 1,
  },
  nav: {
    top: '155px',
    left: 'auto',
    right: 0,
    position: 'sticky',
  },
  main: {
    flex: 3,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 25,
    },
  },
}))

export default function Sidebar({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Hidden xsDown>
        <div className={classes.sidebar}>
          <nav className={classes.nav}>
            <Paper>
              <Navigation />
            </Paper>
          </nav>
        </div>
      </Hidden>
      <main className={classes.main}>{children}</main>
    </div>
  )
}
