import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

const GridContainer = withStyles(theme => ({
  root: {
    borderTop: '1px solid #E8E8E8',
  },
}))(({ classes, children }) => (
  <Grid container className={classes.root}>
    {children}
  </Grid>
))

export default GridContainer
