import gql from 'graphql-tag'

export const GET_AVILYS_DOCUMENTS = gql`
  query {
    avilys {
      documents {
        name
        recipient
        status
        dateSubmitted
        url
        responses {
          name
          url
        }
      }
    }
  }
`

export const GET_AVILYS_TEMPLATES = gql`
  query GetAvilysTemplates {
    avilys {
      templates {
        id
        name
      }
    }
  }
`

export const GET_AVILYS_TEMPLATE = gql`
  query GetAvilysTemplate($id: Long!) {
    avilys {
      getTemplate(id: $id) {
        id
        name
        title
        kind
        recipient
        description
        attachments {
          id
          required
          description
          extensions
          formUrl
        }
      }
    }
  }
`

export const GET_ATTACHMENT_UPLOADS = gql`
  query GetAttachmentUploads($ids: [Long!]!) {
    attachments {
      uploads(ids: $ids) {
        id
        name
        dateAdded
        mimeType
        size
      }
    }
  }
`
