import React, { useState, useEffect } from 'react'
import {useAuthentication} from "../../providers";
import { Redirect } from 'react-router-dom'

export default function VIISPCallbackHandler({query, children}) {
    const { updateToken } = useAuthentication()
    const [redirectTo, setRedirectTo] = useState(null);

    useEffect(() => {
        if (query) {
            if (query.has('token')) {
                updateToken(query.get('token'))
                if (query.has('redirectTo')) {
                    return setRedirectTo(query.get('redirectTo'))
                }
                return setRedirectTo('/');
            }
            return setRedirectTo('/login')
        }
    })

    return redirectTo ? <Redirect to={redirectTo} /> : children || null;
}