import { AdapterLink, Button, Section } from '../index'
import React from 'react'
import { Typography } from '../Section'
import { PaddedBox } from '../../views/documents/MyDocuments'
import { useTranslation } from 'react-i18next'

export default function CreateDocumentComplete() {
  const { t } = useTranslation()
  return (
    <Section
      title={t('create-document.title')}
      subtile={t('create-document.subtitle')}
    >
      <PaddedBox pt={4} style={{ padding: '10px 0' }}>
        <PaddedBox>
          <Typography variant="h1">{t('create-document.success')}</Typography>
        </PaddedBox>
        <Button to="/my-documents" component={AdapterLink}>
          {t('create-document.back')}
        </Button>
      </PaddedBox>
    </Section>
  )
}
