import React from 'react'

export default function Menu2Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Capa_1"
      viewBox="0 0 29.6 24.4"
      {...props}
    >
      <path
        d="M27.9 4.3H5.8C4.8 4.3 4 5.1 4 6v16.7c0 1 .8 1.7 1.7 1.7h22.1c1 0 1.7-.8 1.7-1.7V6c.1-.9-.7-1.7-1.6-1.7zm.4 18.4c0 .2-.2.4-.4.4h-22c-.2 0-.4-.2-.4-.4V12.3h22.9v10.4zm0-11.9H5.4V6.1c0-.2.2-.4.4-.4h22c.2 0 .4.2.4.4v4.7z"
        className="st0"
      />
      <path
        d="M25.6 3.2v-2C25.6.5 25 0 24.4 0H2.3C1 0 0 1 0 2.3v16.6c0 .7.6 1.2 1.2 1.2h1.4v-1.3H1.4V2.5c0-.7.5-1.2 1.2-1.2h21.7v1.9h1.3z"
        className="st0"
      />
    </svg>
  )
}
