import React, { useRef } from 'react'
import MuiTable from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useQuery } from '@apollo/client'
import { withStyles } from '@material-ui/core/styles'
import { Section, Error, Button, AdapterLink } from '../../elements'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import GetAppIcon from '@material-ui/icons/GetApp'
import { GET_AVILYS_DOCUMENTS } from '../../../graphql/query'
import { useConfig } from '@mcity/siauliai-core/src/components/providers/SiauliaiConfigProvider'
import { useTranslation } from 'react-i18next'
import { useAuthentication } from '@mcity/siauliai-core/src/components/providers'

export const PaddedBox = withStyles({
  root: {
    margin: '0 0 21px 0',
  },
})(Box)

const Table = withStyles(theme => ({
  root: {
    borderCollapse: 'collapse',
    borderRadius: 6,
    overflow: 'hidden',
  },
}))(MuiTable)

const StyledTableCell = withStyles(theme => ({
  head: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: 'black',
  },
  body: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
  },
}))(TableCell)

function Documents({ documents }) {
  const { authToken } = useAuthentication()
  const config = useConfig()
  const { t } = useTranslation()
  const downloadFileName = useRef(null)

  const onGetResponseClick = url => {
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Token ${authToken}`,
      },
    })
      .then(response => {
        const headerValue =
          response.headers.get('content-disposition') || 'filename=unknown'
        const split = headerValue.split('filename=')
        downloadFileName.current = split[1]
        return response.blob()
      })
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = downloadFileName.current
        link.click()
        link.remove()
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <Section title={t('Menu My documents')} subtile={t('My docs description')}>
      <PaddedBox>
        <Button to="/services" component={AdapterLink}>
          {t('create-document.button')}
        </Button>
      </PaddedBox>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>{t('browse-document.name')}</StyledTableCell>
            <StyledTableCell>{t('browse-document.recipient')}</StyledTableCell>
            <StyledTableCell>{t('browse-document.status')}</StyledTableCell>
            <StyledTableCell>
              {t('browse-document.date.submitted')}
            </StyledTableCell>
            <StyledTableCell>{t('browse-document.url')}</StyledTableCell>
            <StyledTableCell>{t('browse-document.response')}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents
            .slice()
            .sort(function(a, b) {
              const dateA = new Date(a.dateSubmitted)
              const dateB = new Date(b.dateSubmitted)
              return dateB - dateA
            })
            .map(
              (
                { name, recipient, status, dateSubmitted, url, responses },
                j,
              ) => (
                <TableRow key={`document_${j}`}>
                  <StyledTableCell>{name}</StyledTableCell>
                  <StyledTableCell>{recipient}</StyledTableCell>
                  <StyledTableCell>
                    {t(`browse-document.${status}`)}
                  </StyledTableCell>
                  <StyledTableCell>{dateSubmitted}</StyledTableCell>
                  <StyledTableCell>
                    <a href={url} target="_blank" rel="noreferrer">
                      <PictureAsPdfIcon style={{ color: 'black' }} />
                    </a>
                  </StyledTableCell>
                  <StyledTableCell>
                    {responses
                      ? responses.map(({ name, url }, i) => (
                          <a
                            style={{ cursor: 'pointer' }}
                            key={`response_${i}`}
                            onClick={() =>
                              onGetResponseClick(
                                `${config.endpoint}/rest${url}`,
                              )
                            }
                          >
                            <GetAppIcon style={{ color: 'black' }} />
                          </a>
                        ))
                      : null}
                  </StyledTableCell>
                </TableRow>
              ),
            )}
        </TableBody>
      </Table>
    </Section>
  )
}

export default function MyDocuments({ location }) {
  const { loading, error, data } = useQuery(GET_AVILYS_DOCUMENTS, {
    fetchPolicy: 'network-only',
  })

  if (error) {
    return <Error>{error.message}</Error>
  }

  if (loading && (!data || !data.avilys)) {
    return <CircularProgress color="secondary" />
  }

  return <Documents documents={data.avilys.documents} />
}
