import React from 'react'
import { GridValue, GridTitle, GridContainer } from '../../elements'
import { useTranslation } from 'react-i18next'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export default function CreateDocumentProfile({
  firstName,
  lastName,
  personalCode,
  phone,
  email,
  address,
  agreePortalTerms,
}) {
  const { t } = useTranslation()

  return (
    <GridContainer>
      <GridContainer>
        <GridTitle>{t('create-document.profile.name')}</GridTitle>
        <GridValue>
          {`${firstName.value} ${lastName.value}`.toUpperCase()}
        </GridValue>
      </GridContainer>
      <GridContainer>
        <GridTitle>{t('create-document.profile.personalCode')}</GridTitle>
        <GridValue>{personalCode.value}</GridValue>
      </GridContainer>
      <GridContainer>
        <GridTitle>{t('create-document.profile.address')}</GridTitle>
        <GridValue>{address.value}</GridValue>
      </GridContainer>
      <GridContainer>
        <GridTitle>{t('create-document.profile.contacts')}</GridTitle>
        <GridValue>
          {phone.value}, {email.value}
        </GridValue>
      </GridContainer>
      <GridContainer>
        <FormControlLabel
          control={
            <Checkbox checked={agreePortalTerms.value === 'true'} disabled />
          }
          label={t('create-document.profile.terms')}
        />
      </GridContainer>
    </GridContainer>
  )
}
