import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

const GridValue = withStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
  },
}))(({ classes, children }) => (
  <Grid item className={classes.root} xs={12} sm>
    {children}
  </Grid>
))

export default GridValue

