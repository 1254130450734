import React from 'react'
import MuiPaper from '@material-ui/core/Paper'
import MuiTypography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

export const Typography = withStyles(theme => ({
  h1: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(22),
    lineHeight: theme.typography.pxToRem(28),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  h2: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    marginBottom: 30,
  },
}))(MuiTypography)

const Paper = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
  },
  elevation1: {
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
    },
  },
}))(MuiPaper)

export default function Section({ title, subtile, children, className }) {
  return (
    <Paper className={className}>
      <Typography variant="h1">{title}</Typography>
      <Typography variant="h2">{subtile}</Typography>
      {children}
    </Paper>
  )
}
