import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    textDecoration: 'none',
  },
}))

export default function PlainLink(props) {
  const classes = useStyles()

  return <RouterLink className={classes.root} {...props} />
}
