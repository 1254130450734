import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Content,
  Header,
  Footer,
  Container as ContainerBase,
} from '../elements'
import ErrorBoundary from '@mcity/siauliai-core/src/components/elements/error/ErrorBoundary'
import { ErrorController } from '@mcity/siauliai-core/src/components/elements/error/ErrorController'
import { useAppState } from '@mcity/siauliai-core/src/components/providers/AppStateProvider'

const Container = withStyles(theme => ({
  root: {
    padding: '155px 24px 0 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '100px 24px 0 24px',
    },
  },
}))(ContainerBase)

export default function Main({ children }) {
  const { error } = useAppState()

  return (
    <>
      <Content>
        <Header />
        <Container>
          <ErrorBoundary>
            <ErrorController error={error}>{children}</ErrorController>
          </ErrorBoundary>
        </Container>
      </Content>
      <Footer />
    </>
  )
}
