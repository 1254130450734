import React, { useState, useMemo } from 'react'
import clsx from 'clsx'
import gql from 'graphql-tag'
import Grid from '@material-ui/core/Grid'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { Section, Error } from '../elements'
import { useTranslation } from 'react-i18next'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: 16,
  },
  card: {
    borderRadius: 6,
    border: '1px solid #FFECA6',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
  },
  header: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    backgroundColor: '#FFECA6',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& span:first-child': {
      marginRight: 8,
    },
  },
  expend: {
    padding: '10px 8px',
    color: '#FAAB00',
  },
  entries: {
    margin: '0 16px',
    '& > *': {
      padding: '16px',
    },
    '& > *:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  cardIcon: {
    verticalAlign: 'middle',
    color: '#FAAB00',
  },
  childrenSection: {
    marginTop: '16px',
  },
  childSelect: {
    width: '100%',
    marginBottom: '16px',
  },
  childSelectIconOutlined: {
    right: '20px',
  },
}))

function Cards({ data }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [open, setOpen] = useState(-1)
  const [openChildCard, setOpenChildCard] = useState(-1)
  const [selectedChild, setSelectedChild] = useState(
    data.children.length ? data.children[0].personCode : undefined,
  )

  const selectedChildData = useMemo(() => {
    return data.children.find(child => child.personCode === selectedChild)
  }, [selectedChild, data])

  const handleChildChange = event => {
    setSelectedChild(event.target.value)
  }

  const formatDate = date => {
    return moment(date, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm:ss')
  }

  return (
    <Box maxWidth="560px">
      <Section title={t('my-cards.title')} subtile={t('my-cards.subtitle')}>
        {data.cards.length === 0 && <h3>{t('my-cards.cards-not-found')}</h3>}
        {data.cards.map((card, i) => (
          <div key={i} className={clsx(classes.card, i && classes.spacing)}>
            <div className={classes.header}>
              <div className={classes.title}>
                <span>
                  <CreditCardIcon
                    width="16"
                    height="23"
                    className={classes.cardIcon}
                  />
                </span>
                <span>{card.externalNumber}</span>
              </div>
              <Button
                className={classes.expend}
                onClick={() => setOpen(open === i ? -1 : i)}
              >
                {open === i ? <ExpandLess /> : <ExpandMore />}
              </Button>
            </div>
            <Collapse in={open === i} timeout="auto" unmountOnExit>
              <div className={classes.entries}>
                <Grid container>
                  <Grid item xs={12}>
                    {t('my-cards.card-number')}
                  </Grid>
                  <Grid item xs={12} className={classes.bold}>
                    {card.externalNumber}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    {t('my-cards.card-type')}
                  </Grid>
                  <Grid item xs={12} className={classes.bold}>
                    {card.cardType}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    {t('my-cards.name')}
                  </Grid>
                  <Grid item xs={12} className={classes.bold}>
                    {card.firstName}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    {t('my-cards.surname')}
                  </Grid>
                  <Grid item xs={12} className={classes.bold}>
                    {card.lastName}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    {t('my-cards.date-of-issue')}
                  </Grid>
                  <Grid item xs={12} className={classes.bold}>
                    {formatDate(card.issueDate)}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    {t('my-cards.card-issuer')}
                  </Grid>
                  <Grid item xs={12} className={classes.bold}>
                    {card.cardIssuer}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    {t('my-cards.valid-from')}
                  </Grid>
                  <Grid item xs={12} className={classes.bold}>
                    {formatDate(card.validFrom)}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    {t('my-cards.valid-till')}
                  </Grid>
                  <Grid item xs={12} className={classes.bold}>
                    {formatDate(card.validTill)}
                  </Grid>
                </Grid>
              </div>
            </Collapse>
          </div>
        ))}
      </Section>
      {data.children.length > 0 && (
        <Section
          title={t('my-cards.childrens-ecards-title')}
          subtile={t('my-cards.childrens-ecards-subtitle')}
          className={classes.childrenSection}
        >
          <Select
            value={selectedChild}
            onChange={handleChildChange}
            className={classes.childSelect}
            variant="outlined"
            inputProps={{
              classes: {
                iconOutlined: classes.childSelectIconOutlined,
              },
            }}
          >
            {data.children.map((child, i) => (
              <MenuItem
                key={i}
                value={child.personCode}
              >{`${child.name} ${child.surname} (${child.personCode})`}</MenuItem>
            ))}
          </Select>
          {selectedChildData && selectedChildData.cards.length === 0 && (
            <h3>{t('my-cards.cards-not-found')}</h3>
          )}
          {selectedChildData &&
            selectedChildData.cards.map((card, i) => (
              <div key={i} className={clsx(classes.card, i && classes.spacing)}>
                <div className={classes.header}>
                  <div className={classes.title}>
                    <span>
                      <CreditCardIcon
                        width="16"
                        height="23"
                        className={classes.cardIcon}
                      />
                    </span>
                    <span>{card.externalNumber}</span>
                  </div>
                  <Button
                    className={classes.expend}
                    onClick={() =>
                      setOpenChildCard(openChildCard === i ? -1 : i)
                    }
                  >
                    {open === i ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </div>
                <Collapse in={openChildCard === i} timeout="auto" unmountOnExit>
                  <div className={classes.entries}>
                    <Grid container>
                      <Grid item xs={12}>
                        {t('my-cards.card-number')}
                      </Grid>
                      <Grid item xs={12} className={classes.bold}>
                        {card.externalNumber}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        {t('my-cards.card-type')}
                      </Grid>
                      <Grid item xs={12} className={classes.bold}>
                        {card.cardType}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        {t('my-cards.name')}
                      </Grid>
                      <Grid item xs={12} className={classes.bold}>
                        {card.firstName}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        {t('my-cards.surname')}
                      </Grid>
                      <Grid item xs={12} className={classes.bold}>
                        {card.lastName}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        {t('my-cards.date-of-issue')}
                      </Grid>
                      <Grid item xs={12} className={classes.bold}>
                        {formatDate(card.issueDate)}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        {t('my-cards.card-issuer')}
                      </Grid>
                      <Grid item xs={12} className={classes.bold}>
                        {card.cardIssuer}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        {t('my-cards.valid-from')}
                      </Grid>
                      <Grid item xs={12} className={classes.bold}>
                        {formatDate(card.validFrom)}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        {t('my-cards.valid-till')}
                      </Grid>
                      <Grid item xs={12} className={classes.bold}>
                        {formatDate(card.validTill)}
                      </Grid>
                    </Grid>
                  </div>
                </Collapse>
              </div>
            ))}
        </Section>
      )}
    </Box>
  )
}

const GET_CARDS = gql`
  query {
    personCard {
      cards {
        externalNumber
        cardType
        lastName
        firstName
        issueDate
        validFrom
        validTill
        cardIssuer
        cardNumber
      }
      children {
        personCode
        name
        surname
        birthDate
        cards {
          externalNumber
          cardType
          lastName
          firstName
          issueDate
          validFrom
          validTill
          cardIssuer
          cardNumber
        }
      }
    }
  }
`

export default function MyCards() {
  const { loading, error, data } = useQuery(GET_CARDS)
  const { t } = useTranslation()

  if (error) {
    return <Error>{t('my-cards.cards-error')}</Error>
  }

  if (loading && (!data || !data.personCard)) {
    return <CircularProgress color="secondary" />
  }

  return <Cards data={data.personCard} />
}
