import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    left: 'calc(50% - 16px)',
    maxWidth: '720px',
    margin: '16px',
    padding: '16px',
    borderRadius: '8px',
    transform: 'translateX(-50%)',
    display: 'flex',
    backgroundColor: 'rgb(253, 237, 237)',
    color: 'rgb(95, 33, 32)',
  },
  wrap: {},
  innerWrap: {
    marginLeft: '16px',
    width: '100%',
  },
  title: {
    margin: '4px 0 0',
    fontSize: '0.9rem',
  },
  message: {
    margin: '8px 0 0',
  },
  btn: {
    marginTop: '16px',
  },
}))

export const ErrorNotice = ({ error }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const restart = () => {
    window.location.reload()
  }

  return (
    <div className={classes.container} role="alert">
      <ErrorOutlineIcon style={{ color: '#ef5350' }} />
      <div className={classes.innerWrap}>
        <h4 className={classes.title}>{t('error-has-occurred')}</h4>
        {error && error.errorIdList && error.errorIdList.length > 0 && (
          <p className={classes.message}>
            {t('error-code')}{' '}
            {error.errorIdList.map((errorId, index) =>
              index > 0 ? `, ${errorId}` : errorId,
            )}
          </p>
        )}
        <Button
          className={classes.btn}
          onClick={() => restart()}
          variant="outlined"
          color="default"
        >
          {t('restart-app')}
        </Button>
      </div>
    </div>
  )
}
