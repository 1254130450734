import React from "react";
import {useQuery} from "@apollo/client";
import {GET_SIAULIAI_DISCOUNTS} from "../../graphql/query";
import Error from "../elements/Error";
import SiauliaiPreloader from "../elements/SiauliaiPreloader";
import {useTranslation} from "react-i18next";

export default function withSiauliaiDiscounts(WrappedComponent) {
    return function () {
        const { t } = useTranslation();
        const { loading, error, data } = useQuery(GET_SIAULIAI_DISCOUNTS, {fetchPolicy: 'no-cache'})
        if (error) {
            return <Error>{error.message}</Error>
        }
        if (loading && (!data || !data.siauliaiDiscounts)) {
            return <SiauliaiPreloader/>;
        }
        if (data.siauliaiDiscounts.discounts) {
            const { list, user } = data.siauliaiDiscounts.discounts;
            const flatList = list.reduce((acc, discount) => {
                return acc.concat(discount.merchants.map(m => ({...discount,
                    merchant: m})));
            }, []);
            return (
                <WrappedComponent discounts={{user, list: flatList}} />
            );
        } else {
            return <WrappedComponent discounts={{user: undefined, list: []}} />
        }
    }
}
