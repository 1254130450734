import React, { useState } from 'react'
import DocumentService from './DocumentService'
import { Section } from '../../elements'
import { GridValue, GridTitle, GridContainer } from '../../elements'
import DocumentTemplateAutocomplete from '../../elements/documents/DocumentTemplateAutocomplete'
import CreateDocumentComplete from '../../elements/documents/CreateDocumentComplete'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export default function DocumentServices() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false)
  const [selectedServiceId, setSelectedServiceId] = useState(Number(id))
  if (showSuccessMessage) {
    return <CreateDocumentComplete />
  }
  return (
    <Section
      title={t('create-document.title')}
      subtile={t('create-document.subtitle')}
    >
      <GridContainer>
        <GridTitle>{t('create-document.document.template')}</GridTitle>
        <GridValue>
          <DocumentTemplateAutocomplete
            setSelectedServiceId={setSelectedServiceId}
            selectedServiceId={selectedServiceId}
          />
        </GridValue>
      </GridContainer>
      {!!selectedServiceId && (
        <DocumentService
          serviceId={selectedServiceId}
          onSuccess={() => setShowSuccessMessage(true)}
        />
      )}
    </Section>
  )
}
