import React from 'react'
import { ErrorNotice } from './ErrorNotice'
import { ErrorState } from '../../providers/AppStateProvider'
import { withRouter } from 'react-router-dom'

let timeoutId = null

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: ErrorState.None }
    this.historyUnsubscribe = null
  }

  componentDidMount() {
    const { history } = this.props

    this.historyUnsubscribe = history.listen(() => {
      if (this.state.error !== ErrorState.None) {
        /*
         * when immediately cleaning error
         * before router has the chance to mount new component
         * wrapper that switches between content and error, could attempt to render component that was shielded by error
         * it would result in network request that potentially could throw error (assuming that previously it already failed)
         *
         * in order to work around it
         * we use intermediate state to transition away from error
         */
        this.setState({
          error: ErrorState.InTransition,
        })

        if (timeoutId) {
          clearTimeout(timeoutId)
        }

        timeoutId = setTimeout(() => {
          this.setState({
            error: ErrorState.None,
          })
        })
      }
    })
  }

  componentWillUnmount() {
    if (this.historyUnsubscribe) {
      this.historyUnsubscribe()
    }
  }

  componentDidCatch() {
    /*
     * as state changes have potential to trigger more things that could throw more errors and result in endless cycle
     * in case of several sequential errors (e.g., multiple failing network requests) we only show the first one
     */
    if (this.state.error !== ErrorState.Present) {
      this.setState({
        error: ErrorState.Present,
      })
    }
  }

  render() {
    if (this.state.error === ErrorState.Present) {
      return <ErrorNotice />
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
