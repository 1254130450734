import MuiTextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

const TextField = withStyles(theme => ({
  root: {
    '& label': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(14),
      color: 'black',
      transform: 'translate(10px, 14px) scale(1)',
    },
    '& label.Mui-focused': {
      color: '#FAAB00',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FAAB00',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FAAB00',
      },
      '&:hover fieldset': {
        borderColor: '#FAAB00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FAAB00',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: 12,
    },
  },
}))(MuiTextField)

export default TextField
