import React from 'react'
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MuiButton from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { SiauliaiHeaderIcon, BurgerIcon, Navigation } from '../elements'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LANGUAGES } from '@mcity/siauliai-core/src/lng/i18n'

const LinkButton = withStyles(theme => ({
  root: {
    minWidth: 'auto',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(13),
  },
  text: {
    color: '#F9B006',
  },
  disabled: {
    color: `${theme.palette.common.black} !important`,
  },
}))(MuiButton)

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: 'white',
  },
  toolBar: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 1160,
    [theme.breakpoints.down('xs')]: {
      borderBottom: '1px solid #E8E8E8',
    },
  },
  borderBottom: {
    borderBottom: '1px solid #E8E8E8',
  },
}))

function LanguageSwitch() {
  const { i18n } = useTranslation()
  const changeLanguage = l => {
    i18n.changeLanguage(l).then(() => {
      localStorage.setItem('lng', l)
    })
  }
  return (
    <Box flexShrink="0">
      {LANGUAGES.map(lng => (
        <LinkButton
          key={`lng-${lng}`}
          disabled={lng === i18n.language}
          onClick={() => changeLanguage(lng)}
        >
          {lng}
        </LinkButton>
      ))}
    </Box>
  )
}

export default function Header() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const location = useLocation()
  const isMenuVisible = [
    '/my-profile',
    '/my-e-cards',
    '/my-discounts',
    '/my-documents',
    '/order-e-card',
  ].find(
    route => route === location.pathname || `${route}/` === location.pathname,
  )

  React.useEffect(() => setOpen(false), [location.pathname])

  return (
    <AppBar position="fixed" elevation={0} className={classes.appBar}>
      <Box display="flex" justifyContent="center">
        <Toolbar
          className={clsx(
            classes.toolBar,
            location.pathname === '/login' && classes.borderBottom,
          )}
        >
          <Hidden smUp>
            {isMenuVisible ? (
              <>
                <Link to="/">
                  <SiauliaiHeaderIcon width="188" height="58" />
                </Link>
                <IconButton onClick={() => setOpen(true)}>
                  <BurgerIcon width="23" height="20" fill="#FAAB00" />
                </IconButton>
                <Drawer variant="temporary" anchor="right" open={open}>
                  <Navigation onClose={() => setOpen(false)} />
                </Drawer>
              </>
            ) : (
              <span>
                <SiauliaiHeaderIcon width="188" height="58" />
              </span>
            )}
          </Hidden>
          <Hidden xsDown>
            {isMenuVisible ? (
              <>
                <Link to="/">
                  <SiauliaiHeaderIcon width="252" height="86" />
                </Link>
                <LanguageSwitch />
              </>
            ) : (
              <span>
                <SiauliaiHeaderIcon width="252" height="86" />
              </span>
            )}
          </Hidden>
          {!isMenuVisible && <LanguageSwitch />}
        </Toolbar>
      </Box>
    </AppBar>
  )
}
