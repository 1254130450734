import React from 'react'

export default function Menu1Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.3 83.2" {...props}>
      <path
        fill="#faab00"
        d="M0 66.9v14.5c0 1 .8 1.9 1.9 1.9h59.6c1 0 1.9-.8 1.9-1.9V66.9C63.4 60 57.9 54.3 51 54c-.1 0-9.6.3-11.1-10 4.6-3.2 8.1-9.2 9-16.3.2-.2.3-.4.4-.6.4-.9.7-1.9 1-2.9 1.2-4.7.8-9.5-1-13.6-2-4.3-5.3-7.2-9.3-8.4-.8-.2-1.7-.4-2.6-.5C35.1.6 32.6 0 30.1 0c-9.7 0-17.7 8.5-17.7 19 0 2.7.5 5.4 1.6 7.8.1.3.3.5.5.7.6 4.6 2.2 8.8 4.7 12.2 1.3 1.7 2.7 3.2 4.3 4.3-1.4 10.3-11 10-11.1 10C5.5 54.3 0 59.9 0 66.9zM46.6 23c-2-2.1-3.5-4.7-4.2-7.6-.3-1-1.1-1.7-2.1-1.7-1-.1-1.9.5-2.3 1.4-.2.6-.6 1.1-1.2 1.5-.6.5-1.3.7-2.1.7H22c-2.3 0-4.3.9-5.8 2.4v-.8c0-8.4 6.2-15.3 13.9-15.3 2.1 0 4.1.5 6 1.5.2.1.5.2.8.2.7 0 1.5.2 2.1.3 6.1 2 9.5 9.7 7.6 17.4zM31.7 42.9c-7.5 0-13.7-8.6-13.7-19.2.7-1.6 2.3-2.6 4.1-2.6h12.8c1.6 0 3.1-.5 4.3-1.4.2-.2.4-.3.6-.5 1.2 2.9 3.1 5.5 5.5 7.6-.5 4.4-2.1 8.4-4.5 11.4-2.7 3.1-5.8 4.7-9.1 4.7zm10.1 11.8l-9.8 14c-.1.1-.2.1-.3.1-.1 0-.2 0-.3-.1l-9.8-14c2.8-2.1 4.8-5.3 5.4-8.8 1.5.5 3.1.8 4.7.8s3.2-.3 4.7-.8c.6 3.5 2.6 6.7 5.4 8.8zM3.7 79.5V66.9c0-5 4.1-9.1 9.1-9.1.1 0 2.7 0 5.5-1.2l10 14.3c.8 1.1 2 1.7 3.3 1.7 1.3 0 2.6-.6 3.3-1.7l10-14.3c2.4 1.1 5.4 1.2 5.5 1.2 5 0 9.1 4.1 9.1 9.1v12.6H3.7z"
      />
    </svg>
  )
}
