import React from 'react'
// import { ErrorNotice } from './ErrorNotice'
import { ErrorState } from '../../providers/AppStateProvider'

export const ErrorController = ({ children, error }) => {
  return (
    <>
      {error.state === ErrorState.None ? (
        children
      ) : error.state === ErrorState.InTransition ? (
        <></>
      ) 
      // : error.state === ErrorState.Present ? (
      //   <ErrorNotice error={error} />
      // ) 
      : (
        children
      )}
    </>
  )
}
