import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  GridValue,
  GridTitle,
  GridContainer,
  InputLabel,
  InputBase,
} from '../elements'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles(theme => ({
  warningChild: {
    textAlign: 'center',
    width: '60vw',
    color: 'red',
  },
}))

const PHONE_NUMBER_LENGTH = 12

const ADD_CHILD_PHONE = gql`
  mutation AddChildPhone($personCode: String!, $phone: String!) {
    children {
      addChildPhone(personCode: $personCode, phone: $phone)
    }
  }
`

const CONFIRM_CHILD_PHONE = gql`
  mutation ConfirmChildPhone(
    $code: String!
    $phone: String!
    $personCode: String!
  ) {
    children {
      confirmChildPhone(code: $code, phone: $phone, personCode: $personCode)
    }
  }
`

const REMOVE_CHILD_PHONE = gql`
  mutation RemoveChildPhone($personCode: String!, $phone: String!) {
    children {
      removeChildPhone(personCode: $personCode, phone: $phone)
    }
  }
`

export default function ProfileChild({
  childData,
  refetchChildren = () => {},
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [phone, setPhone] = useState(
    childData.phone !== null ? childData.phone : '',
  )
  const [phoneConfirmationCode, setPhoneConfirmationCode] = useState('')
  const [enablePhoneVerification, setEnablePhoneVerification] = useState(false)
  const [warningPhone, setWarningPhone] = useState(null)
  const [agreeTerms, setAgreeTerms] = useState(childData.phone !== null)
  const [
    warningPhoneConfirmationCode,
    setWarningPhoneConfirmationCode,
  ] = useState(null)

  useEffect(() => {
    if (childData.phone !== phone) {
      setPhone(childData.phone !== null ? childData.phone : '')
    }
    setPhoneConfirmationCode('')
    setAgreeTerms(childData.phone !== null)
  }, [childData])

  const [addChildPhone] = useMutation(ADD_CHILD_PHONE, {
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
  })

  const [confirmChildPhone] = useMutation(CONFIRM_CHILD_PHONE, {
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
  })

  const [removeChildPhone] = useMutation(REMOVE_CHILD_PHONE, {
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
  })

  const onVerifyButtonClick = () => {
    setWarningPhone(null)
    addChildPhone({
      variables: { phone: phone, personCode: childData.personCode },
    }).then(data => {
      if (data?.data?.children?.addChildPhone) {
        setEnablePhoneVerification(true)
      } else {
        setWarningPhone(t('login.error-phone-number'))
      }
    })
  }

  const onConfirmButtonClick = () => {
    setWarningPhone(null)
    confirmChildPhone({
      variables: {
        code: phoneConfirmationCode,
        phone: phone,
        personCode: childData.personCode,
      },
    }).then(data => {
      if (data?.data?.children?.confirmChildPhone) {
        setEnablePhoneVerification(false)
        setPhoneConfirmationCode('')
        refetchChildren()
      } else {
        setWarningPhoneConfirmationCode(t('profile.error-auth-code'))
      }
    })
  }

  const onRemovePhoneBtnClick = () => {
    setWarningPhone(null)
    removeChildPhone({
      variables: { phone: childData.phone, personCode: childData.personCode },
    }).then(data => {
      if (data?.data?.children?.removeChildPhone) {
        refetchChildren()
      }
    })
  }

  return (
    <Box>
      <GridContainer>
        <GridTitle>{t('Name profile')}</GridTitle>
        <GridValue>
          {`${childData.name} ${childData.surname}`.toUpperCase()}
        </GridValue>
      </GridContainer>
      <GridContainer>
        <GridTitle>{t('Personal ID profile')}</GridTitle>
        <GridValue>{childData.personCode}</GridValue>
      </GridContainer>
      <GridContainer>
        <GridTitle>
          <InputLabel>{t('profile-phone-number')}</InputLabel>
        </GridTitle>
        <GridValue>
          <InputBase
            fullWidth
            value={phone}
            onChange={e => setPhone(e.target.value)}
            disabled={enablePhoneVerification || childData.phone}
            style={{ marginRight: '5px' }}
          />
          {(childData.phone && (
            <Button
              style={{ width: '235px' }}
              onClick={() => onRemovePhoneBtnClick()}
            >
              {t('profile.delete-phone')}
            </Button>
          )) || (
            <Button
              disabled={
                childData.phone === phone ||
                phone.length !== PHONE_NUMBER_LENGTH ||
                enablePhoneVerification ||
                !agreeTerms
              }
              style={{ width: '235px' }}
              onClick={onVerifyButtonClick}
            >
              {t('profile.verify')}
            </Button>
          )}
        </GridValue>
        {warningPhone && (
          <div className={classes.warningChild}>{warningPhone}</div>
        )}
      </GridContainer>
      {enablePhoneVerification && (
        <GridContainer classes={classes}>
          <GridTitle>
            <InputLabel>{t('profile.verification-code')}</InputLabel>
          </GridTitle>
          <GridValue>
            <InputBase
              fullWidth
              value={phoneConfirmationCode}
              onChange={e => setPhoneConfirmationCode(e.target.value)}
              style={{ marginRight: '5px' }}
            />
            <Button style={{ width: '235px' }} onClick={onConfirmButtonClick}>
              {t('profile.confirm')}
            </Button>
          </GridValue>
          {warningPhoneConfirmationCode && (
            <div className={classes.warningChild}>
              {warningPhoneConfirmationCode}
            </div>
          )}
        </GridContainer>
      )}
      <GridContainer>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreeTerms}
              onChange={() => setAgreeTerms(!agreeTerms)}
              disabled={childData.phone !== null || enablePhoneVerification}
            />
          }
          label={t('profile.children-terms')}
        />
      </GridContainer>
    </Box>
  )
}
