import React from 'react'

export default function Menu4Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Capa_1"
      viewBox="0 0 14.9 16.6"
      {...props}
    >
      <path
        d="M9.8 6.6H3.1c-.3 0-.4.2-.4.4s.2.4.4.4h6.7c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM7.2 9.2H3.1c-.2 0-.4.2-.4.4s.1.4.4.4h4.1c.2 0 .4-.2.4-.4s-.2-.4-.4-.4z"
        className="st0"
      />
      <path
        d="M2.4 16.3c-1.3 0-2.4-1.1-2.4-2.4V2.4C0 1.1 1.1 0 2.4 0h8c1.3 0 2.4 1.1 2.4 2.4v4c0 .3-.2.5-.5.5s-.3-.2-.3-.5v-4c0-.8-.7-1.5-1.5-1.5h-8C1.7.9 1 1.6 1 2.4v11.5c0 .8.7 1.5 1.5 1.5h2.2c.3 0 .5.2.5.5s-.2.5-.5.5H2.4zM14.3 9.1c-.3-.3-.8-.5-1.3-.5s-.9.2-1.3.5l-4 4s-.1.1-.1.2l-.9 2.9c0 .1 0 .3.1.4.1.1.2.1.3.1h.1l2.9-.8c.1 0 .1 0 .2-.1l4-4c.3-.3.5-.8.5-1.3.1-.6-.1-1-.5-1.4zm-1.4 3l-3 3-2.2.6.2-.7.5-1.6 3-2.9 1.5 1.6zm.2-2.8c.3 0 .6.1.8.3.4.4.4 1.1 0 1.6l-.4.4-1.6-1.6.4-.4c.2-.2.5-.3.8-.3z"
        className="st0"
      />
      <path
        d="M9.8 4H3.1c-.3 0-.4.2-.4.4s.2.4.4.4h6.7c.2 0 .4-.2.4-.4S10 4 9.8 4z"
        className="st0"
      />
    </svg>
  )
}
