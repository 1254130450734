import React from 'react'
import PlainLink from '@mcity/siauliai-core/src/components/elements/PlainLink'

const AdapterLink = React.forwardRef((props, ref) => (
  <PlainLink
    innerRef={ref}
    {...props}
    to={props.query ? `${props.to}/${props.query}` : props.to}
  />
))
export default AdapterLink
