import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import DocumentAttachmentUpload from './DocumentAttachmentUpload'
import { withStyles } from '@material-ui/core/styles'

const Container = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 0',
  },
}))(Box)

const AttachmentNumber = withStyles(theme => ({
  root: {
    flexBasis: '30px',
    flexShrink: 1,
    paddingRight: '10px',
  },
}))(Box)

const AttachmentBody = withStyles(theme => ({
  root: {
    flexBasis: '100%',
    flexShrink: 5,
  },
}))(Box)

export default function CreateDocumentAttachment({
  attachmentConfig,
  num,
  error,
  onChange,
}) {
  const [attachment, setAttachment] = useState(null)
  const onSetAttachment = attachment => {
    setAttachment(attachment)
    onChange(attachment)
  }
  return (
    <Container>
      <AttachmentNumber style={{ color: error ? 'red' : 'black' }}>
        {num}.{attachmentConfig.required ? '*' : ' '}
      </AttachmentNumber>
      <AttachmentBody style={{ color: error ? 'red' : 'black' }}>
        {attachmentConfig.description}
      </AttachmentBody>
      <AttachmentBody>
        <DocumentAttachmentUpload
          attachmentConfig={attachmentConfig}
          attachment={attachment}
          setAttachment={onSetAttachment}
        />
      </AttachmentBody>
    </Container>
  )
}
