import React from 'react'
import gql from 'graphql-tag'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiCheckbox from '@material-ui/core/Checkbox'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMutation } from '@apollo/client'
import { withStyles } from '@material-ui/core/styles'
import { Section, TextField, Button, Error, Success } from '../elements'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

const ORDER_ECARD = gql`
  mutation OrderECard($message: String!, $terms: Boolean!) {
    siauliaiEcard {
      order(message: $message, terms: $terms) {
        success
      }
    }
  }
`

const FormControlLabel = withStyles(theme => ({
  label: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  },
}))(MuiFormControlLabel)

const Checkbox = withStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
  },
}))(MuiCheckbox)

function ResponseMessage({ loading, called, data }) {
  if (loading || !called || !data || !data.siauliaiOrderEcard) {
    return null
  }

  if (data.siauliaiOrderEcard.success) {
    return (
      <Grid item>
        <Success>Success</Success>
      </Grid>
    )
  }

  return (
    <Grid item>
      <Error>Please try again later</Error>
    </Grid>
  )
}

export default function OrderCard() {

  // TODO: hidden temporarily by client's request (SAULI-57)
  return (
    <Redirect path="/" />
  )

  const { t } = useTranslation()
  const [checked, setChecked] = React.useState(false)
  const [description, setDescription] = React.useState('')
  const [orderECard, { error, loading, called, data }] = useMutation(
    ORDER_ECARD,
  )

  if (error) {
    return <Error>{error.message}</Error>
  }

  function handleSubmit(e) {
    e.preventDefault()
    orderECard({
      variables: {
        message: description,
        terms: checked,
      },
    }).then(() => {
      setChecked(false)
      setDescription('')
    })
  }

  return (
    <Box maxWidth="560px">
      <Section title={t('Menu order e-cards')} subtile={t('Order ecards desc')}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <TextField
                id="description"
                label={t('Order e-cards description field')}
                variant="outlined"
                fullWidth
                multiline
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    value={checked}
                    disabled={loading}
                  />
                }
                label={t('Checkbox order e-card')}
              />
            </Grid>
            <Grid item>
              <Button type="submit" fullWidth disabled={loading || !checked}>
                {loading ? (
                  <Box display="flex" alignItems="center">
                    <CircularProgress color="inherit" size={24} thickness={8} />
                  </Box>
                ) : (
                  t('Order e-card button')
                )}
              </Button>
            </Grid>
            <ResponseMessage loading={loading} called={called} data={data} />
          </Grid>
        </form>
      </Section>
    </Box>
  )
}
