import React from 'react'

export default function(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      enableBackground="new 0 0 422.6 376.5"
      viewBox="0 0 422.6 376.5"
      {...props}
    >
      <path
        d="M334.4 184c-1.5-.6-3.2-.3-4.4.9l-46 46c-.8.8-1.2 1.8-1.2 2.8v88.7H54.1V93.7h183.3c1.1 0 2.1-.4 2.8-1.2l46.1-46.1c1.1-1.1 1.5-2.9.9-4.4-.6-1.5-2.1-2.5-3.7-2.5H29.4C13.2 39.6 0 52.8 0 69.1v278c0 16.2 13.2 29.4 29.4 29.4h267.7c21.9 0 39.8-17.8 39.8-39.8V187.6c-.1-1.6-1-3-2.5-3.6zm-37.3 184.5H29.4c-11.8 0-21.4-9.6-21.4-21.4v-278c0-11.8 9.6-21.4 21.4-21.4h244.4l-38.1 38.1H50.1c-2.2 0-4 1.8-4 4v236.7c0 2.2 1.8 4 4 4h236.7c2.2 0 4-1.8 4-4v-91.1l38.1-38.1v139.4c0 17.6-14.3 31.8-31.8 31.8z"
        className="st0"
      />
      <path
        d="M415.7 29.3L393.4 6.9c-9.2-9.2-24.2-9.2-33.4 0L191.4 175.2c-6.1 6.1-16 6.1-22.1 0l-28.6-28.6c-9.2-9.2-24.2-9.2-33.4 0L84.9 169c-9.2 9.2-9.2 24.2 0 33.4l78.7 78.7c4.6 4.6 10.7 6.9 16.7 6.9s12.1-2.3 16.7-6.9L415.7 62.7c4.5-4.5 6.9-10.4 6.9-16.7s-2.4-12.3-6.9-16.7zM410.1 57L191.4 275.4c-6.1 6.1-16 6.1-22.1 0l-78.7-78.7c-6.1-6.1-6.1-16 0-22.1l22.4-22.4c3-3 7-4.6 11.1-4.6 4 0 8 1.5 11.1 4.6l28.6 28.6c9.2 9.2 24.2 9.2 33.4 0L365.6 12.6c6.1-6.1 16-6.1 22.1 0L410.1 35c3 3 4.6 6.9 4.6 11.1s-1.7 8-4.6 10.9z"
        className="st0"
      />
      <path
        d="M372.4 21.9l-51.6 51.6c-1.6 1.6-1.6 4.1 0 5.7.8.8 1.8 1.2 2.8 1.2s2-.4 2.8-1.2L378 27.6c1.6-1.6 1.6-4.1 0-5.7-1.5-1.6-4-1.6-5.6 0zM104.8 182.8c-1.6-1.6-4.1-1.6-5.7 0-1.6 1.6-1.6 4.1 0 5.7l32.5 32.5c.8.8 1.8 1.2 2.8 1.2s2-.4 2.8-1.2c1.6-1.6 1.6-4.1 0-5.7l-32.4-32.5z"
        className="st0"
      />
    </svg>
  )
}
