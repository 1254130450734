import HttpStatus from 'http-status-codes'

export const cordovaHttpFetchImpl = async (url, options) => {
  const cordova = window.cordova
  if (!cordova) {
    return fetch(url, options)
  }
  const http = cordova.plugin.http
  http.setDataSerializer('json')
  http.setHeader('Content-Type', 'application/json')
  http.setHeader('Accept', 'application/json')
  // TODO: remove this line, used for testing purposes to access https://siauliai-dev.pantera.dev/api/news/list
  http.setHeader('Authorization', 'Basic c2lhdWxpYWk6c2lhdWxpYWkuMTIz')
  const opts = {
    method: options.method.toLowerCase(),
  }
  if (options.body) {
    opts.data = JSON.parse(options.body)
  }
  const response = await new Promise((resolve, reject) => {
    http.sendRequest(url, opts, resolve, reject)
  })
  const responseBody =
    typeof response.data === `object`
      ? JSON.stringify(response.data)
      : response.data
  const headers = new Headers()
  Object.entries(response.headers).forEach(function ([key, value]) {
    headers.append(key, value)
  })
  return new Response(responseBody, {
    status: response.status,
    statusText: HttpStatus.getStatusText(response.status),
    headers,
  })
}
