import React, { useMemo, useState } from 'react'
import CreateDocumentProfile from './CreateDocumentProfile'
import { useAuthentication } from '@mcity/siauliai-core/src/components/providers'
import { GridValue, GridTitle, GridContainer, InputBase } from '../../elements'
import CreateDocumentAttachment from './CreateDocumentAttachment'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/client'
import { CREATE_AVILYS_DOCUMENT } from '../../../graphql/mutation'
import { Button, Error, AdapterLink } from '../index'
import Box from '@material-ui/core/Box'
import { PaddedBox } from '../../views/documents/MyDocuments'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'

const CreateDocumentInput = withStyles(theme => ({
  root: {
    width: '100%',
    height: '100px',
    alignItems: 'flex-start',
  },
}))(InputBase)

const DescriptionGridValue = withStyles(theme => ({
  root: {
    padding: '16px 0',
  },
}))(GridValue)

const CenteredGridValue = withStyles(theme => ({
  root: {
    padding: '14px 0',
    justifyContent: 'center',
  },
}))(GridValue)

const FormControlLabel = withStyles(theme => ({
  label: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
  },
}))(MuiFormControlLabel)

const useStyles = makeStyles(theme => ({
  errorContent: {
    display: 'flex',
  },
  errorButton: {
    display: 'flex',
    width: '220px',
    marginLeft: '10px',
  },
  errorText: {
    display: 'flex',
  },
}))

const ADDITIONAL_ATTACHMENT_CONFIG = {
  id: -1,
  required: false,
  description: 'Papildomas priedas',
  formUrl: null,
  extensions: [],
}

const ADDITIONAL_ATTACHMENT_LIMIT = 3

export default function CreateDocumentFromTemplate({ template, onSuccess }) {
  const { t } = useTranslation()
  const { profile } = useAuthentication()
  const classes = useStyles()

  const showProfileError = useMemo(() => {
    return (
      profile.address.value === '' ||
      profile.phone.value === '' ||
      profile.email.value === '' ||
      profile.agreePortalTerms.value !== 'true'
    )
  }, [profile])

  const [createDocument, { loading }] = useMutation(CREATE_AVILYS_DOCUMENT)
  const handleSubmit = e => {
    e.preventDefault()
    if (isFormValid()) {
      createDocument({
        variables: {
          templateId: template.id,
          body: '',
          notes: documentNotes,
          notification: documentNotificationKind,
          attachments: documentAttachments
            .filter(att => att.attachmentId)
            .map(att => ({
              attachmentConfigId: att.id,
              attachmentId: att.attachmentId,
            })),
        },
      })
        .then(({ data }) => {
          if (data?.avilys?.createDocument) {
            const success = data.avilys.createDocument.success
            setShowError(!success)
            if (success) {
              onSuccess()
            }
          }
        })
        .catch(() => {
          setShowError(true)
        })
    }
  }

  const [formErrors, setFormErrors] = React.useState({})
  const [showError, setShowError] = useState(false)
  const [legalAccepted, setLegalAccepted] = useState(false)
  const [documentNotes, setDocumentNotes] = useState('')
  const [documentNotificationKind, setDocumentNotificationKind] = useState(
    'portale',
  )
  const [documentAttachments, setDocumentAttachments] = useState(
    template.attachments,
  )

  React.useEffect(() => {
    if (template) {
      setDocumentAttachments(template.attachments)
    }
  }, [template])

  const isFormValid = () => {
    const _errors = []
    if (!legalAccepted) {
      _errors.legalAccepted = 'Please agree with terms and conditions'
    }
    documentAttachments
      .filter(att => att.required)
      .forEach(requiredAttachment => {
        if (!requiredAttachment.attachmentId) {
          _errors[`documentAttachments_${requiredAttachment.id}`] = t(
            'create-document.attachment.mandatory',
          )
        }
      })
    setFormErrors(_errors)
    return Object.keys(_errors).length === 0
  }

  const addAdditionalAttachment = () => {
    setDocumentAttachments(
      documentAttachments.concat(ADDITIONAL_ATTACHMENT_CONFIG),
    )
  }

  const canAddAdditionalAttachments =
    documentAttachments.length - template.attachments.length <
    ADDITIONAL_ATTACHMENT_LIMIT
  return (
    <form onSubmit={handleSubmit}>
      <DescriptionGridValue>{template.description}</DescriptionGridValue>
      {showProfileError && (
        <div style={{ marginBottom: '10px' }}>
          <Error>
            <div className={classes.errorContent}>
              <div className={classes.errorText}>
                {t('create-document.profileDataWarning')}
              </div>
              <Button
                to="/my-profile"
                component={AdapterLink}
                className={classes.errorButton}
              >
                {t('Menu item my profile')}
              </Button>
            </div>
          </Error>
        </div>
      )}
      <CreateDocumentProfile {...profile} />
      <GridContainer>
        <CenteredGridValue fullWidth>{template.recipient}</CenteredGridValue>
      </GridContainer>
      <GridContainer>
        <CenteredGridValue>
          {template.kind === 'REQUEST' ? 'PRAŠYMAS' : 'PARAIŠKA'}
        </CenteredGridValue>
      </GridContainer>
      <GridContainer>
        <CenteredGridValue>{template.title}</CenteredGridValue>
      </GridContainer>
      <GridContainer>
        <GridTitle>{t('create-document.document.notes')}</GridTitle>
        <br />
        <CreateDocumentInput
          id="notes-input"
          fullWidth
          value={documentNotes}
          onChange={e => setDocumentNotes(e.target.value)}
          disabled={loading}
          error={Boolean(formErrors.documentNotes)}
        />
      </GridContainer>
      {documentAttachments.map((attachmentConfig, i) => (
        <CreateDocumentAttachment
          error={Boolean(
            formErrors[`documentAttachments_${attachmentConfig.id}`],
          )}
          key={`att-${i}`}
          attachmentConfig={attachmentConfig}
          num={i + 1}
          onChange={attachment => {
            setDocumentAttachments(
              documentAttachments.map((existing, num) =>
                num === i
                  ? {
                      ...existing,
                      attachmentId: attachment ? attachment.id : null,
                    }
                  : existing,
              ),
            )
          }}
        />
      ))}
      {canAddAdditionalAttachments && (
        <Button onClick={addAdditionalAttachment}>
          {t('create-document.attachment.add')}
        </Button>
      )}
      {template.attachments.filter(att => att.required).length > 0 && (
        <div style={{ paddingTop: '14px', fontSize: '0.875rem' }}>
          * - {t('create-document.attachment.mandatory')}
        </div>
      )}
      <div style={{ height: '14px' }} />
      <FormControl
        required
        error={Boolean(formErrors.legalAccepted)}
        style={{ flexDirection: 'row' }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={legalAccepted}
              onChange={() => setLegalAccepted(!legalAccepted)}
              value={legalAccepted}
              disabled={loading}
            />
          }
          label={
            <div
              style={{
                fontSize: '1rem',
                color: '#808080',
                paddingLeft: '1rem',
                lineHeight: 1,
              }}
            >
              {t('create-document.legal')}
            </div>
          }
        />
      </FormControl>
      <FormControl component="fieldset">
        <GridTitle>{t('create-document.notification.title')}</GridTitle>
        <RadioGroup
          aria-label="documentNotificationKind"
          name="documentNotificationKind"
          value={documentNotificationKind}
          onChange={event => setDocumentNotificationKind(event.target.value)}
        >
          <FormControlLabel
            value="portale"
            control={<Radio />}
            label={t('create-document.notification.portal')}
          />
          <FormControlLabel
            value="savivaldybėje"
            control={<Radio />}
            label={t('create-document.notification.municipality')}
          />
          <FormControlLabel
            value="el. paštu"
            control={<Radio />}
            label={t('create-document.notification.email')}
          />
          <FormControlLabel
            value="paštu"
            control={<Radio />}
            label={t('create-document.notification.post')}
          />
        </RadioGroup>
      </FormControl>
      <Box pt={4}>
        {showError && (
          <PaddedBox>
            <Error>{t('create-document.error')}</Error>
          </PaddedBox>
        )}
        <Button fullWidth type="submit" disabled={loading || showProfileError}>
          {loading ? (
            <Box display="flex" alignItems="center">
              <CircularProgress color="inherit" size={24} thickness={8} />
            </Box>
          ) : (
            t('create-document.submit')
          )}
        </Button>
      </Box>
    </form>
  )
}
