import React from 'react'
import List from '@material-ui/core/List'
import MuiListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import {
  Menu1Icon,
  Menu2Icon,
  Menu3Icon,
  Menu4Icon,
  Menu5Icon,
  UserIcon,
  CloseIcon,
  Button,
  AdapterLink,
  UserName,
} from '../elements'
import { useTranslation } from 'react-i18next'

const ListItem = withStyles(theme => ({
  root: {
    color: theme.palette.common.black,
    padding: '8px 38px 8px 38px',
    '& .MuiListItemIcon-root': {
      minWidth: '32px',
    },
  },
  selected: {
    backgroundColor: '#FFECA6 !important',
  },
}))(MuiListItem)

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
  },
  name: {
    flex: 1,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(18),
    color: theme.palette.common.black,
    marginLeft: '16px',
    textTransform: 'uppercase',
  },
  footer: {
    padding: 21,
    [theme.breakpoints.down('xs')]: {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
}))

const LINKS = [
  {
    url: '/my-profile',
    text: 'Menu item my profile',
    icon: () => <Menu1Icon width="20" height="25" fill="#FAAB00" />,
  },
  {
    url: '/my-e-cards',
    text: 'Menu My e-cards',
    icon: () => <Menu2Icon width="21" height="18" fill="#FAAB00" />,
  },
  {
    url: '/my-discounts',
    text: 'Manu My discounts',
    icon: () => <Menu3Icon width="19" height="15" fill="#FAAB00" />,
  },
  {
    url: '/my-documents',
    text: 'Menu My documents',
    icon: () => <Menu4Icon width="19" height="21" fill="#FAAB00" />,
  },
  // TODO: hidden temporarily by client's request (SAULI-57)
  // {
  //   url: '/order-e-card',
  //   text: 'Menu order e-cards',
  //   icon: () => <Menu5Icon width="21" height="20" fill="#FAAB00" />,
  // },
]

export default function Navigation({ onClose }) {
  const { t } = useTranslation()
  const location = useLocation()
  const classes = useStyles()

  return (
    <>
      <div className={classes.header}>
        <UserIcon width="60" height="60" />
        <div className={classes.name}>
          <UserName>
            {({ firstName, lastName }) => (
              <>
                <div>{firstName.value}</div>
                <div>{lastName.value}</div>
              </>
            )}
          </UserName>
        </div>
        <Hidden smUp>
          <IconButton onClick={onClose}>
            <CloseIcon width="22" height="22" />
          </IconButton>
        </Hidden>
      </div>
      <List>
        {LINKS.map(({ url, text, icon: Icon }, index) => (
          <ListItem
            key={index}
            to={url}
            query={location.search}
            button
            divider
            selected={
              location.pathname === url || location.pathname === `${url}/`
            }
            component={AdapterLink}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText>{t(text)}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Box flex="1" />
      <div className={classes.footer}>
        <Button fullWidth to="/logout" component={AdapterLink}>
          {t('Log out')}
        </Button>
      </div>
    </>
  )
}
