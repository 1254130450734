import React from 'react'
import { Button } from 'siauliai-web-app/src/components/elements'
import { withVIISPAuthTicket } from './withVIISPAuthTicket'
import { useTranslation } from 'react-i18next'

function VIISPWebLoginButton({ ticket, url, disabled }) {
  const { t } = useTranslation()
  return (
    <form name="REQUEST" method="post" action={url}>
      <input type="hidden" name="ticket" value={ticket} />
      <Button type="submit" size="large" width={100} disabled={disabled}>
        {t('Log-in button')}
      </Button>
    </form>
  )
}

export default withVIISPAuthTicket(VIISPWebLoginButton)
