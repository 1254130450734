import React, { useContext, useState, useEffect } from 'react'
import { Network } from '@capacitor/network'
import { useHistory } from 'react-router-dom'

export const ErrorState = {
  None: 0,
  InTransition: 1,
  Present: 2,
}

let timeoutId = null

const Context = React.createContext({
  _error: {
    state: ErrorState.None,
  },
  isOnline: null,
})

export const useAppState = () => useContext(Context)

export const AppStateProvider = ({ children }) => {
  const history = useHistory()

  const [_error, _setError] = useState({ state: ErrorState.None })
  const [isOnline, setIsOnline] = useState(true)

  useEffect(() => {
    Network.getStatus().then(({ connected }) => setIsOnline(connected))

    const networkListener = Network.addListener(
      'networkStatusChange',
      ({ connected }) => {
        setIsOnline(connected)
      },
    )

    return () => {
      networkListener.remove()
    }
  }, [])

  useEffect(() => {
    const historyUnsubscribe = history.listen(() => {
      clearError()
    })

    return () => {
      historyUnsubscribe()
    }
  }, [])

  const clearError = () => {
    /*
     * when immediately cleaning error
     * before router has the chance to mount new component
     * wrapper that switches between content and error, could attempt to render component that was shielded by error
     * it would result in network request that potentially could throw error (assuming that previously it already failed)
     *
     * in order to work around it
     * we use intermediate state to transition away from error
     */
    if (_error) {
      _setError({
        state: ErrorState.InTransition,
      })

      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      timeoutId = setTimeout(() => {
        _setError({
          state: ErrorState.None,
        })
      })
    }
  }

  const setError = (errorObject = {}) => {
    /*
     * as state changes have potential to trigger more things that could throw more errors and result in endless cycle
     * in case of several sequential errors (e.g., multiple failing network requests) we only show the first one
     */
    if (_error.state !== ErrorState.Present) {
      _setError({
        ...errorObject,
        state: ErrorState.Present,
      })
    }
  }

  return (
    <Context.Provider
      value={{
        error: _error,
        isOnline,
        clearError,
        setError,
      }}
    >
      {children}
    </Context.Provider>
  )
}
