import gql from 'graphql-tag'

export const GET_AUTHENTICATION_CONTEXT = gql`
  query GetAuthenticationContext {
    authorizationProvider {
      ownAuthenticationContext {
        token
        userId
        authenticator
        uniqueId
        params {
          key
          value
          isEditable
        }
        associatedAccounts{
          id
          authenticator
          uniqueId
          params {
            key
            value
            isEditable
          }
        }
      }
    }
  }
`

export const GET_NEWS_LIST = gql`
  query GetNewsList ($page: Long!) {
    news (page: $page)
    @rest(type: "News", endpoint: "news", path: "list?{args}") {
      data {
        NewsId
        Type
        Title
        Summary
        Content
        IsVisible
        Date
        CoverImgPath
      }
      meta {
        current_page
        last_page
      }
    }
  }
`

export const GET_SIAULIAI_DISCOUNTS = gql`
  query {
    siauliaiDiscounts {
      discounts {
        user {
          firstName
          lastName
          personalCode
        }
        list {
          name
          description
          validTil
          validFrom
          amountPercent
          merchants {
            code
            name
            email
            phone
            address
            webPage
          }
        }
      }
    }
  }
`

export const LIST_OFFERS = gql`
  query ListOffers {
    offers {
      offers(
        lookup: {
          limit: 100
          skip: 0
          orderBy: "id"
          orderAscending: false
          filter: {
            key: "status"
            value: "APPROVED"
            flip: false
            approximate: false
          }
        }
      ) {
        list {
          id
          companyId
          companyName
          title
          description
          attachmentId
          isGift
          discount
          authenticatedDiscount
          expiry
          status
        }
        count
        totalCount
      }
    }
  }
`

export const GET_OFFER = gql`
  query GetOffer($id: Long!) {
    offers {
      offer(id: $id) {
        id
        companyId
        companyName
        title
        description
        isGift
        attachmentId
        discount
        authenticatedDiscount
        expiry
        status
      }
    }
  }
`

export const GET_COMPANY = gql`
  query GetCompany($id: Long!) {
    companies {
      company(id: $id) {
        id
        name
        regNo
        phone
        status
      }
    }
  }
`