import React from 'react'
import { Section } from 'siauliai-web-app/src/components/elements'
import withSiauliaiDiscounts from '@mcity/siauliai-core/src/components/views/withSiauliaiDiscounts'
import { DiscountList } from '@mcity/siauliai-core/src/components/elements/discounts/DiscountList'
import { useTranslation } from 'react-i18next'

function SiauliaiPortalDiscounts({ discounts }) {
  const { t } = useTranslation()
  return (
    <Section
      title={t('Manu My discounts')}
      subtile={t('My discounts description')}
    >
      {discounts && (
        <DiscountList user={discounts.user} list={discounts.list} />
      )}
    </Section>
  )
}

export default withSiauliaiDiscounts(SiauliaiPortalDiscounts)
