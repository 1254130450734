import React from 'react'
import CreateDocumentFromTemplate from '../../elements/documents/CreateDocumentFromTemplate'
import { useQuery } from '@apollo/client'
import { GET_AVILYS_TEMPLATE } from '../../../graphql/query'
import CircularProgress from '@material-ui/core/CircularProgress'
import Error from '../../elements/Error'
import { useTranslation } from 'react-i18next'

export default function DocumentService({ serviceId, onSuccess }) {
  const { t } = useTranslation()
  const { data, error, loading } = useQuery(GET_AVILYS_TEMPLATE, {
    variables: { id: serviceId },
  })
  if (loading) {
    return <CircularProgress color="secondary" />
  }
  if (error) {
    return <Error>{error.message}</Error>
  }
  const documentTemplate = data?.avilys?.getTemplate
  if (documentTemplate) {
    return (
      <CreateDocumentFromTemplate
        template={documentTemplate}
        onSuccess={onSuccess}
      />
    )
  } else {
    return <Error>{t('table.no-data')}</Error>
  }
}
