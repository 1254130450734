import React from 'react'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import { SiauliaiFooterIcon } from '../elements'
import { useTranslation } from 'react-i18next'
import latLigLogo from '../../../assets/imgs/LATLIT_logo_mix_full_CMYK.jpg'

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.common.black,
  },
  mainSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    borderBottom: '1px solid #404040',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '16px',
    },
  },
  subsection: {
    display: 'flex',
    justifyContent: 'center',
    padding: '14px 24px',
    color: '#616161',
    textAlign: 'center',
  },
  link: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(21),
    color: theme.palette.common.white,
  },
  latLitLogo: {
    height: '90px',
    width: '275px',
    backgroundImage: `url(${latLigLogo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  latLitLogoWrapper: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: '16px',
    },
  },
}))

const LINKS = [
  [
    { text: 'Senectus', url: '#' },
    { text: 'Magna', url: '#' },
    { text: 'Semper', url: '#' },
  ],
  [
    { text: 'Fames', url: '#' },
    { text: 'Etiam', url: '#' },
    { text: 'Senectus', url: '#' },
  ],
  [
    { text: 'Senectus', url: '#' },
    { text: 'Semper', url: '#' },
    { text: 'Facilisis', url: '#' },
  ],
  [
    { text: 'Adipiscing', url: '#' },
    { text: 'Fames', url: '#' },
  ],
]

export default function Footer() {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Box margin="auto" maxWidth={1160}>
        <div className={classes.mainSection}>
          <SiauliaiFooterIcon width="231" height="58" fill="#FAAB00" />
          <Hidden xsDown>
            <Box display="flex" ml="-25px">
              {LINKS.map((links, i) => (
                <Box
                  key={i}
                  display="none"
                  flexDirection="column"
                  px="25px"
                  style={{}}
                >
                  {links.map(({ text, url }, i) => (
                    <Link key={i} href={url} className={classes.link}>
                      {text}
                    </Link>
                  ))}
                </Box>
              ))}
            </Box>
          </Hidden>
          <a
            className={classes.latLitLogoWrapper}
            href="https://latlit.eu/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Box className={classes.latLitLogo} />
          </a>
        </div>
        <div className={classes.subsection}>{t('Copyright')}</div>
      </Box>
    </footer>
  )
}
