import gql from 'graphql-tag'

export const CREATE_AVILYS_DOCUMENT = gql`
  mutation CreateAvilysDocument(
    $templateId: Int!
    $body: String!
    $notes: String!
    $notification: String!
    $attachments: [SiauliaiAttachmentInput!]!
  ) {
    avilys {
      createDocument(
        input: {
          templateId: $templateId
          body: $body
          notes: $notes
          attachments: $attachments
          notification: $notification
        }
      ) {
        success
        oid
      }
    }
  }
`

export const CREATE_ATTACHMENT = gql`
  mutation CreateAttachment(
    $objectId: Long!
    $module: String!
    $uploadId: Long!
  ) {
    attachments {
      addAttachment(
        input: { objectId: $objectId, module: $module, uploadId: $uploadId }
      ) {
        id
        upload {
          id
          name
          dateAdded
          mimeType
          size
        }
      }
    }
  }
`

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($id: Long!) {
    attachments {
      deleteAttachment(id: $id)
    }
  }
`
